<template>
  <div class="gateway-plate">
    <div class="gateway-plate__media">
      <div v-if="logoList[gtwName]" class="gateway-plate__logo">
        <img :src="logoList[gtwName]" :alt="getAltNames(gatewaydata.attributes)" />
      </div>
      <div class="gateway-plate__title">
        {{ getAltNames(gatewaydata.attributes) }}
      </div>
    </div>

    <div class="d-flex flex-column">
      <b v-if="entityName" class="gateway-plate__owner">
        {{ entityName }}
      </b>

      <div
        class="gateway-plate__stripe-data"
        v-if="gtwName === 'stripe' || gtwName === 'stripe-ach'"
      >
        <span v-if="gatewaydata.attributes.gateway_data">
          {{ gatewaydata.attributes.gateway_data.bank_name }}</span
        >
        <span v-if="gatewaydata.attributes.gateway_data">
          {{ gatewaydata.attributes.gateway_data.org_representative }}</span
        >
      </div>

      <div
        v-if="
          accountType &&
          (gtwName === 'stripe' ||
            gtwName === 'stripe-ach' ||
            gtwName === 'stripe-apple-pay' ||
            gtwName === 'stripe-google-pay')
        "
        class="gateway-plate__stripe-data gateway-plate__stripe-data--optional"
      >
        <span>
          {{ $t(`gateways.account_type_${accountType}`, accountType) }}
        </span>
        <span>&#x2022;</span>
        <span>
          {{ gatewaydata.attributes.gateway_data.account_number }}
        </span>
      </div>
    </div>

    <div class="gateway-plate__data">
      <div class="gateway-plate__status">
        <b>
          {{ $t("gateways.created_at", "Created at:") }}
        </b>
        {{ createdAt }}
      </div>

      <div class="gateway-plate__ccy">
        {{ gatewaydata.attributes.primary_currency }}
      </div>

      <div class="gateway-plate__status" :class="{ enabled: gatewaydata.attributes.active }">
        {{ gatewaydata.attributes.active ? $t("gateways.enabled") : $t("gateways.disabled") }}
      </div>

      <div
        @click.prevent="gatewayOverview"
        class="gateway-plate__lead__status"
        :class="{
          'gateway-plate__lead__status_pending': leadStatus === '1',
          'gateway-plate__lead__status_declined': leadStatus === '2',
        }"
        v-if="isMeshulamLeadStatus"
      >
        {{ leadStatusLabel }}
      </div>

      <div class="gateway-plate__action" @click.prevent="gatewayOverview">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 480.606 480.606"
          style="enable-background: new 0 0 480.606 480.606"
          xml:space="preserve"
        >
          <g>
            <rect x="85.285" y="192.5" width="200" height="30" />
            <path
              d="M439.108,480.606l21.213-21.213l-71.349-71.349c12.528-16.886,19.949-37.777,19.949-60.371
              c0-40.664-24.032-75.814-58.637-92.012V108.787L241.499,0H20.285v445h330v-25.313c6.188-2.897,12.04-6.396,17.475-10.429
              L439.108,480.606z M250.285,51.213L299.072,100h-48.787V51.213z M50.285,30h170v100h100v96.957
              c-4.224-0.538-8.529-0.815-12.896-0.815c-31.197,0-59.148,14.147-77.788,36.358H85.285v30h126.856
              c-4.062,10.965-6.285,22.814-6.285,35.174c0,1.618,0.042,3.226,0.117,4.826H85.285v30H212.01
              c8.095,22.101,23.669,40.624,43.636,52.5H50.285V30z M307.389,399.208c-39.443,0-71.533-32.09-71.533-71.533
              s32.089-71.533,71.533-71.533s71.533,32.089,71.533,71.533S346.832,399.208,307.389,399.208z"
            />
          </g>
        </svg>
      </div>

      <div class="plate__action">
        <div class="switch-public--switch">
          <input
            :id="`switch_gateways_turn_off_on_${gatewaydata.id}`"
            v-model="active"
            type="checkbox"
            :disabled="!hasAccess"
            @input="turnOffOn"
          />
          <label :for="`switch_gateways_turn_off_on_${gatewaydata.id}`"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import { getAltNames } from "@/constants/gateway-alt-names";
import moment from "moment-timezone";

export default {
  props: ["gatewaydata"],

  data() {
    return {
      active: null,
    };
  },

  mounted() {
    this.active = this.gatewaydata.attributes.active;
  },

  methods: {
    getAltNames(attrs) {
      let name = getAltNames(attrs.gateway) || attrs.gateway;
      name = name?.replaceAll("-", " ");
      if (this.attrs.label) {
        name += ` (${this.attrs.label})`;
      }
      return name;
    },

    gatewayOverview() {
      this.$emit("view-data", this.gatewaydata);
    },

    turnOffOn() {
      let message = this.$t("dashboard.gateways_turn_off_on_confirmation");
      let tke = !this.active ? "on" : "off";
      let options = {
        okText: this.$t(`dashboard.gateways_turn_${tke}_confirmation_ok`),
        cancelText: this.$t("teams.confirm_cancel"),
      };
      this.$dialog
        .confirm(message, options)
        .then(() => {
          const data = {
            data: {
              type: "gateway",
              id: this.gatewaydata.id,
              attributes: {
                active: this.active,
              },
            },
          };

          const orgId = localStorage.getItem("orgId");

          axios
            .put(urls.gateway.replace(":orgId", orgId).replace(":id", this.gatewaydata.id), data)
            .then(() => {
              this.$store.dispatch("getGateways");
            })
            .catch(() => {
              this.active = this.gatewaydata.attributes.active;
            });
        })
        .catch(() => {
          this.active = this.gatewaydata.attributes.active;
        });
    },
  },

  computed: mapState({
    attrs() {
      return this.gatewaydata.attributes;
    },

    gtwName() {
      return this.gatewaydata.attributes.gateway;
    },

    entityName() {
      return this.gatewaydata.attributes.entity_name;
    },

    accountType() {
      return this.gatewaydata.attributes.gateway_data.account_type;
    },

    createdAt() {
      const date = this.gatewaydata.attributes.created_at;
      return moment(date * 1000).format("YYYY-MM-DD HH:mm");
    },

    isMeshulamLeadStatus() {
      return (
        this.gtwName.includes("meshulam") && this.gatewaydata.attributes.gateway_data.lead_status
      );
    },

    leadStatus() {
      return this.gatewaydata.attributes.gateway_data.lead_status;
    },

    leadStatusLabel() {
      let status = "";
      switch (this.gatewaydata.attributes.gateway_data.lead_status) {
        case "1":
          status = this.$t("dashboard.gateways_in_progress", "In progress");
          break;
        case "2":
          status = this.$t("dashboard.gateways_declined", "Declined");
          break;
        default:
          status = this.$t("dashboard.gateways_unknown", "Unknown");
          break;
      }

      return status;
    },

    logoList: function () {
      const cdnHost =
        "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/";
      let logos = {
        stripe: `${cdnHost}/charidy_images/gateways/stripe.png`,
        "stripe-element": `${cdnHost}/charidy_images/gateways/stripe.png`,
        "stripe-ach": `${cdnHost}/charidy_images/gateways/stripe.png`,
        stripe1: `${cdnHost}/charidy_images/gateways/stripe.png`,
        yaadpay: `${cdnHost}/charidy_images/gateways/yaadpay.png`,
        paypal: `${cdnHost}/charidy_images/gateways/paypal.png`,
        paypalv2: `https://images.ctfassets.net/gkyt4bl1j2fs/273APNSE63eFeQGVaNokaJ/da5948b50660aa0a99b02ea2e6990e2f/Blue.png?w=424&h=84&q=50&fm=webp&bg=transparent`,
        israeltoremet: `${cdnHost}/charidy_images/gateways/israeltoremet.png`,
        payfast: `${cdnHost}/charidy_images/gateways/payfast.png`,
        payme: `${cdnHost}/charidy_images/gateways/payme_01.png`,
        venmo: `${cdnHost}/charidy_images/gateways/venmo_logo_blue.svg`,
        achisomoch: `${cdnHost}/public_upload/1708023150_6431333764373962656534623831383666616564336630383931616431636637_31373038303233313530.png`,
        asserbishvil: `${cdnHost}/charidy_images/gateways/asseb.png`,
        broomfoundation: `${cdnHost}/charidy_images/gateways/broom.jpg`,
        braintree: `${cdnHost}/charidy_images/1560882051285651734.png`,
        mercadopago:
          "https://http2.mlstatic.com/ui/navigation/4.1.4/mercadopago/logo__large@2x.png",
        "hk-bank-transfer": `${cdnHost}/charidy_images/business-and-finance-glyph-10-512.png`,
        meshulam: `${cdnHost}/charidy_images/1603128033630529584.png`,
        "meshulam-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
        "tranzila-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
        "jaffa-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
        "kehilot-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
        zoreyatzedokos: `${cdnHost}/public_upload/1625604140_335bf636f6a7b54521e0a8fb73a41d80_1625604140.png`,
        threepillars: `${cdnHost}/public_upload/1625604113_5adf8a08f84b634255601c00731b1ff6_1625604113.png`,
        "checkout-fi": `${cdnHost}/public_upload/1633978140_8f30fd61588aea0ab2a016859da18522_1633978140.jpg`,
        "mancal-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
        kcbobov: "https://online.kcbobov.co.uk/assets/images/logo.png",
        yadshlomo: "https://yadshlomo.org/assets/images/logo.png",
        belzvouchers: "https://belzvouchers.com/assets/images/logo.png",
        uta: `${cdnHost}/public_upload/1708023171_3761373764663633633838326462383338363065363231333932356236323265_31373038303233313731.png`,
        mhtrust: `${cdnHost}/public_upload/1654181780_8aa03b8a611b307207537b5b703dadea_1654181780.png`,
        peach: `${cdnHost}/images/281/logo-PEACH_1675767208.png`,
        "nedarim-plus-native-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
        "peach-bit": `${cdnHost}/charidy_images/1603128111820534712.png`,
      };

      return logos;
    },
    hasAccess: (state) => {
      return state.account.access.CanEditOrganizationGatewayList;
    },
  }),
};
</script>

<style lang="scss" scoped>
.gateway-plate {
  min-height: 90px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e6eaed;
  background-color: #ffffff;
  font-family: $vito-light;
  font-size: 16px;
  @media only screen and (min-width: 468px) {
    padding: 15px 40px;
  }
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  &__media {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 180px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6eaed;
    @media only screen and (min-width: 1024px) {
      margin-right: 20px;
      margin-bottom: initial;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    height: 20px;
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    img {
      height: 20px;
      width: auto;
      max-width: 80px;
    }
  }
  &__title {
    padding: 0 0 0 10px;
    text-transform: capitalize;
    font-family: $vito-light;
    font-size: 16px;
    color: $fd;
  }
  &__owner {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: #20434e;
    margin-bottom: 10px;
    @media only screen and (min-width: 1024px) {
      margin-bottom: initial;
    }
  }

  &__data {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media only screen and(min-width: 1024px) {
      width: auto;
      justify-content: initial;
    }
  }

  &__stripe-data {
    display: flex;
    align-items: center;
    gap: 10px;
    &--optional {
      gap: 5px;
      font-size: 15px;
      color: $fl;
      & > :first-child {
        text-transform: capitalize;
      }
    }
  }

  &__ccy {
    width: 90px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $vito-bold;
    @media only screen and(min-width: 1024px) {
      width: auto;
      margin-right: 15px;
    }
  }
  &__verification {
    margin-right: 15px;
    min-width: 90px;
    font-size: 16px;
    text-transform: capitalize;
  }
  &__status {
    margin-right: auto;
    min-width: 65px;
    color: $fl;
    text-transform: capitalize;
    &.enabled {
      color: $bb-100;
    }
    @media only screen and(min-width: 1024px) {
      margin-right: 15px;
    }
  }
  &__lead__status_pending {
    color: $yellow;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    padding: 0 5px;
    font-weight: 600;
  }
  &__lead__status_declined {
    color: $red;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    padding: 0 5px;
    font-weight: 600;
  }
  &__action {
    min-height: 25px;
    min-width: 25px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 22px;
      height: auto;
      fill: $fd;
    }
  }
}
</style>
