<template>
  <div>
    <!-- Modal Component -->
    <b-modal
      id="ticketModal"
      size="lg"
      content-class="modal-content-dashboard"
      @hide="clearData"
      no-close-on-backdrop
    >
      <div slot="modal-header">
        <h2 v-if="type === 'add'">{{ $t("dashboard.tickets_add_modal_title") }}</h2>
        <h2 v-else>{{ $t("dashboard.tickets_edit_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hide">
          {{ $t("dashboard.donation_add_cancel") }}
        </button>
        <button
          @click.prevent="submitForm"
          class="bttn bttn--lg bttn--orange"
          :disabled="disabledSave"
        >
          {{ $t("dashboard.tickets_save_btn") }}
        </button>
      </div>

      <form v-if="!loading" id="ticketModalForm">
        <error :response.sync="error" :alert="true"></error>

        <div v-if="!showFor(['ticket_free_price'])" class="mb-3">
          <div
            v-for="([c, q], i) in entries"
            :key="`code-quantity-list-item-${i}`"
            class="row mb-1"
          >
            <div class="col-5">
              {{ (getCodeData(c) && getCodeData(c).title) || c }}
              <b-badge v-if="isNewTicket(c)" variant="success" class="ml-2">
                {{ $t("dashboard.tickets_new_ticket", "New") }}
              </b-badge>
            </div>

            <div class="col-5">{{ q }}</div>

            <div class="col-2 d-flex">
              <button class="bttn bttn-round m-auto my-auto" type="button" @click="removeTicket(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <item-list v-if="showFor(['ticket_free_price'])" :items="items" />

        <ValidationObserver
          v-if="
            !showFor(['ticket_free_price']) && Object.keys(config.list).length !== entries.length
          "
          ref="observer"
          tag="div"
          mode="passive"
          class="row"
        >
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="entities-data__label">
                {{ $t("dashboard.tickets_form_tiket") }}
              </label>
              <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                <b-form-select
                  v-model="entry[0]"
                  :options="options"
                  :class="{ 'has-error': errors.length }"
                ></b-form-select>
                <div
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                  class="invalid-feedback d-block is-danger"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="entities-data__label">
                {{ $t("dashboard.tickets_form_quantity") }}
              </label>
              <ValidationProvider name=" " :rules="quantityInputRules" v-slot="{ errors }">
                <b-form-input
                  v-model="entry[1]"
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  :class="{ 'has-error': errors.length }"
                  :disabled="selectedTicketData.inventory === 1"
                >
                </b-form-input>
                <div
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                  class="invalid-feedback d-block is-danger"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 text-center">
            <button class="bttn bttn--lg bttn--blue" @click.prevent="addTicket">
              {{ $t("dashboard.tickets_form_add_tiket") }}
            </button>
          </div>
        </ValidationObserver>

        <item-form v-if="showFor(['ticket_free_price'])" @add="addItem" />

        <error class="mt-3" :response.sync="error" :alert="true"></error>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import itemList from "./ticketModalItemList.vue";
import itemForm from "./ticketModalItemForm.vue";

import error from "@/components/error-message.vue";

export default {
  props: {
    updateList: {
      type: Function,
      default: () => () => {},
    },
  },

  components: {
    error,
    itemList,
    itemForm,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      type: "add",
      originalModuleData: {},
      entry: [null, 1],
      items: [],
      entries: [],
      moduleId: 0,
      error: [],
      loading: false,
      submiting: false,
    };
  },

  computed: {
    ...mapState({
      config: (state) => state.ticket.config,
      cId: (state) => state.dashboard.selectedCampaign,
    }),

    usecase() {
      return this.config.usecase;
    },

    list() {
      return Object.fromEntries(this.entries);
    },

    options() {
      const list = [...Object.entries(this.config.list)];
      list.sort(([, aV], [, bV]) => aV.order - bV.order);
      return [
        {
          value: null,
          text: this.$t("dashboard.tickets_form_select_tiket"),
          disabled: true,
        },
        ...list
          .map(([k, v]) => ({
            value: k,
            text: v.title,
          }))
          .filter(({ value: k }) => {
            const t = this.config.list[k];
            if (t.inventory > 0 && t.purchases >= t.inventory) {
              return false;
            }
            return !this.list[k];
          }),
      ];
    },

    disabledSave() {
      let list = [];
      if (this.showFor(["ticket_free_price"])) {
        list = this.items;
      } else {
        list = Object.keys(this.list);
      }
      return this.submiting || list.length === 0;
    },

    selectedTicketData() {
      const tk = this.entry[0];
      if (tk) {
        return this.config.list[tk];
      }

      return {};
    },

    quantityInputRules() {
      const rules = { required: true, min_value: 1 };

      if (this.selectedTicketData.inventory > 0) {
        rules.max_value = this.selectedTicketData.inventory;
      }

      return rules;
    },
  },

  methods: {
    ...mapActions({
      fetchTicket: "ticket/fetchTicket",
      fetchCreateTicket: "ticket/fetchCreateTicket",
      fetchUpdateTicket: "ticket/fetchUpdateTicket",
    }),

    showFor(list = [""]) {
      return list.includes(this.usecase);
    },

    clearData() {
      this.entry = [null, 1];
      this.entries = [];
      this.items = [];
      this.sending = false;
      this.loading = false;
      this.errors = [];
    },

    open(type = "add", mID) {
      this.type = type;
      if (type === "edit" && mID) {
        this.loading = true;
        this.moduleId = mID;
        this.fetchTicket([this.cId, mID]).then(({ attributes }) => {
          this.originalModuleData = attributes.data;
          this.entries = this.sortedEntries(Object.entries(attributes.data.code_quantity));
          this.items = attributes.data.items || [];
          this.loading = false;
        });
      } else if (this.showFor(["ticket_free_price"])) {
        this.entries.push(["ticket_free_price", 0]);
      }
      this.$root.$emit("bv::show::modal", "ticketModal");
    },

    hide() {
      this.$root.$emit("bv::hide::modal", "ticketModal");
    },

    getCodeData(code) {
      return this.config.list[code];
    },

    sortedEntries(entries) {
      // Sort the code_quantity entries based on order, falling back to alphabetical
      const sortedEntries = entries.sort((a, b) => {
        const dataA = this.config.list[a[0]];
        const dataB = this.config.list[b[0]];

        // If both items have order values, sort by order
        if (dataA && dataB) {
          return dataA.order - dataB.order;
        }

        // If only one has order, prioritize the one with order
        if (dataA) return -1;
        if (dataB) return 1;

        // If neither has order, sort alphabetically
        return a[0].localeCompare(b[0]);
      });

      return sortedEntries.filter(([k, v]) => k && Number(v)).map(([k, v]) => [k, Number(v)]);
    },

    addTicket() {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          this.entries.push(this.entry);
          this.entries = this.sortedEntries(this.entries);
          this.entry = [null, 1];

          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          });
        }
      });
    },

    isNewTicket(key) {
      if (this.type === "edit" && this.moduleId) {
        const codeQuantity = this.originalModuleData?.code_quantity || {};
        return !(key in codeQuantity);
      }

      return false;
    },

    addItem(item) {
      this.entries[0][1] += 1;
      this.items.push(item);
    },

    removeTicket(index) {
      if (this.showFor(["ticket_free_price"])) {
        this.entries[0][1] -= 1;
        this.entries.splice(index, 1);
      } else {
        this.entries.splice(index, 1);
        this.entries = this.sortedEntries(this.entries);
      }
    },

    submitForm() {
      this.submiting = true;
      const data = {
        ...this.originalModuleData,
        code_quantity: this.list,
      };

      if (this.showFor(["ticket_free_price"])) {
        data.items = this.items;
      }

      if (this.type === "add") {
        this.fetchCreateTicket([this.cId, data])
          .then(({ data }) => {
            this.successHandler();

            let message = {
              title: this.$t("dashboard.ticket_id_title"),
              body: `${data.data.attributes.id}`,
            };

            let options = {
              title: this.$t("dashboard.ticket_id_title"),
              okText: this.$t("dashboard.journal_ad_ok_btn"),
            };

            this.$dialog.alert(message, options);
          })
          .catch(this.errorHandler);
      } else if (this.type === "edit") {
        this.fetchUpdateTicket([this.cId, this.moduleId, data])
          .then(this.successHandler)
          .catch(this.errorHandler);
      }
    },

    successHandler() {
      this.submiting = false;
      this.hide();
      this.updateList();
    },

    errorHandler(err) {
      this.submiting = false;
      this.error = err.response;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.has-error {
  border-color: #f26e66;
  &::placeholder {
    color: #f26e66;
  }
}

.entities-data {
  &__label {
    font-family: $vito-light;
    text-transform: uppercase;
    font-size: 14px;
    color: #414359;
    line-height: 1;
    &--required {
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: $lr-100;
      }
    }
  }
}
</style>
