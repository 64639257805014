<template>
  <b-modal id="addOrganization" size="lg" @hidden="clearData">
    <div slot="modal-header">
      <h2>{{ $t("account.add_organization_title") }}</h2>
      <div class="modal-header__subtitle">{{ $t("account.add_organization_subtitle") }}</div>
    </div>

    <div slot="modal-footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">Cancel</button>
      <button
        form="organization-add"
        :class="loadind ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        :disabled="loadind"
      >
        {{ $t("account.add_organizations") }}
      </button>
    </div>

    <form id="organization-add" @submit.prevent="submitForm" class="entities-data">
      <error v-if="errors" :errors="errors"></error>

      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group
            :label="$t('account.language')"
            class="account-card__select account-card__inputs"
          >
            <b-form-select v-model="accountSettings.data.attributes.lang" :options="languageList" />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label pt-0 not-required">{{ $t("account.timezone") }}</label>

            <multiselect v-model="multiselectData" :options="timezone" label="text" track-by="text">
            </multiselect>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group :label="$t('account.org_name')" class="account-card__inputs">
            <b-form-input v-model="accountSettings.data.attributes.name"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <b-form-group :label="$t('account.org_site')" class="account-card__inputs">
              <b-form-input v-model="accountSettings.data.attributes.website"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12">
          <b-form-group :label="$t('account.org_about')" class="account-card__inputs">
            <b-form-textarea
              v-model="accountSettings.data.attributes.about"
              :rows="3"
              :max-rows="6"
              :no-resize="true"
            >
            </b-form-textarea>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";

import Multiselect from "vue-multiselect";

export default {
  data: function () {
    return {
      accountSettings: {
        data: {
          attributes: {
            lang: "",
            name: "",
            website: "",
            about: "",
            logo: "",
          },
        },
      },

      multiselectData: null,

      errors: [],

      loadind: false,
    };
  },

  components: {
    Multiselect,
    error,
  },

  computed: mapState({
    languageList: (state) => {
      let langArr = state.account.languages;

      let resultArr = [];

      for (var i = 0; i < langArr.length; i++) {
        let obj = {
          text: langArr[i].attributes.name,
          value: langArr[i].attributes.code,
        };

        resultArr.push(obj);
      }

      return resultArr;
    },

    timezone: (state) => {
      let timeZone = state.account.timeZones;

      let resultArr = [];

      for (var i = 0; i < timeZone.length; i++) {
        let obj = {
          text: timeZone[i].attributes.name,
          value: timeZone[i].attributes.timezone,
        };

        resultArr.push(obj);
      }

      return resultArr;
    },
  }),

  watch: {
    multiselectData: function () {
      this.setTimezone();
    },
  },

  methods: {
    clearData: function () {
      this.accountSettings = {
        data: {
          attributes: {
            lang: "",
            name: "",
            website: "",
            about: "",
            logo: "",
          },
        },
      };

      this.multiselectData = null;

      this.errors = [];

      this.loadind = false;
    },

    openModal: function () {
      this.$root.$emit("bv::show::modal", "addOrganization");
    },

    hideModal: function () {
      this.$root.$emit("bv::hide::modal", "addOrganization");
    },

    setTimezone: function () {
      if (this.multiselectData) {
        this.accountSettings.data.attributes.timezone = this.multiselectData.value;
      }
    },

    submitForm: function () {
      this.errors = [];

      this.loading = true;

      axios

        .post(urls.getOrganizations, this.accountSettings)
        .then(() => {
          this.loading = false;

          this.hideModal();

          this.$store.dispatch("getAccountInfo");
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    uploadOrgLogo: function (e) {
      this.errors = [];

      let file = e.target.files[0];

      let formData = new FormData();
      formData.append("file", file);

      axios

        .post(urls.uploadOrgLogo.replace(":orgId", this.orgId), formData)
        .then(() => {
          this.accountSettings.data.attributes.logo = "";
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    deleteLogo: function () {
      this.errors = [];

      axios

        .delete(urls.uploadOrgLogo.replace(":orgId", this.orgId))
        .then(() => {
          this.accountSettings.data.attributes.logo = "";
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.entities-data {
  &__labels {
    color: gray;
    text-transform: capitalize;
  }
  &__label {
    color: gray;
    text-transform: capitalize;
    &--required {
      padding-right: 10px;
      position: relative;
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: red;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
.entities-add {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  color: #20434e;
  background-color: #ffffff;
}
.btn-oval {
  width: auto;
  height: 45px;
  padding: 0 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  font-family: "ProximaNova-Bold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  background-color: #cc6d4c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover,
  &:active {
    background-color: darken(#cc6d4c, 5%);
    box-shadow: none;
    outline: 0;
  }
  &:focus {
    background-color: #cc6d4c;
    box-shadow: none;
    outline: 0;
  }
  &--cancel {
    background-color: gray;
    &:hover,
    &:active {
      background-color: darken(gray, 5%);
    }
    &:focus {
      background-color: gray;
    }
  }
}
.invalid .multiselect .multiselect__tags {
  border: 1px solid red;
}

.invalid .multiselect__tags {
  border: 1px solid red;
}
</style>
