<template>
  <div class="data-view-wrapper clearfix" v-if="gatewayInfo && !loading">
    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.gateway_name") }}
      </div>
      <div class="data-view__data">
        {{ getAltNames(gatewayInfo.attributes.gateway) || gatewayInfo.attributes.gateway }}
      </div>
    </div>

    <div class="data-view" v-if="entityName">
      <div class="data-view__title">
        {{ $t("gateways.legal_entity") }}
      </div>
      <div class="data-view__data">
        {{ entityName.attributes.name }}
      </div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.status") }}
      </div>
      <div class="data-view__data">
        {{ gatewayInfo.attributes.active ? $t("gateways.enabled") : $t("gateways.disabled") }}
      </div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.primary_currency") }}
      </div>
      <div class="data-view__data">
        {{ gatewayInfo.attributes.primary_currency }}
      </div>
    </div>

    <div
      class="data-view"
      v-for="(value, key) in orderedItems(gatewayInfo.attributes.gateway_data)"
      :key="key"
    >
      <div class="data-view__title">
        {{ $t("gateways." + key, key.replaceAll("_", " ")) }}
        <span v-if="key === 'statement_display'">
          <tooltip icon="info">
            <div class="help-tooltip__message">{{ $t("gateways.info_message") }}</div>
          </tooltip>
        </span>
      </div>

      <div v-if="isValueHTML(key)" class="data-view__data" v-html="value"></div>

      <div v-else-if="!(edit === key)" class="data-view__data">
        {{ key == "verification_due_by" ? setTime(value) : value }}
      </div>

      <div v-if="showTestMode(key)" class="switch-public--switch">
        <input
          :id="`switch_gateways_turn_off_on_${key}`"
          v-model="testMode"
          type="checkbox"
          @input="testModeOffOn"
        />
        <label :for="`switch_gateways_turn_off_on_${key}`"></label>
      </div>

      <div
        v-if="!(edit === key) && isEditable(key) && isEditableForGateway(key)"
        class="data-view__action"
      >
        <button class="bttn bttn-round" @click="setEdit(key, value)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>

      <div v-if="edit === key" class="edit-field">
        <div class="form-unit data-view__data">
          <div v-if="key === 'disable_card_swiper'">
            <b-form-checkbox :id="key" :name="key" v-model="editForm[key]" v-validate="'required'">
            </b-form-checkbox>
          </div>
          <div v-if="key === 'statement_display'" class="form-unit__addon-wrap">
            <div class="form-unit__addon-icon form-unit__addon-icon--wide">CHARIDY</div>
            <input
              v-model="editForm[key]"
              type="text"
              :name="key"
              class="form-control form-unit__input"
              v-validate="'required|max:14'"
            />
          </div>
          <div v-if="key === 'custom_label'" class="form-unit__addon-wrap">
            <input
              v-model="editForm[key]"
              type="text"
              :name="key"
              class="form-control form-unit__input"
              v-validate="'required|max:255'"
            />
          </div>
          <div v-if="key === 'message'" class="form-unit__addon-wrap">
            <textarea
              v-model="editForm[key]"
              type="text"
              :name="key"
              class="form-control form-unit__textarea"
              v-validate="'required|max:255'"
              rows="3"
            />
          </div>
          <div v-if="key === 'charge_currency_list'" class="form-group">
            <multiselect
              :options="gateway.attributes.available_charge_currency_list"
              :multiple="true"
              :value="editForm[key]"
              :key="chargeCurrencyListKey"
              @input="onChargeCurrencyListChange"
            />
          </div>
          <span v-show="vErrors.has(key)" class="v-help is-danger">
            {{ $t("gateways.statement_length_error") }}
          </span>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <div class="data-view__action my-1">
            <input
              type="submit"
              class="bttn bttn--orange bttn--sm"
              :value="$t('account.save')"
              @click="save"
            />
          </div>
          <div class="data-view__action my-1">
            <input
              type="submit"
              class="bttn bttn--blue bttn--sm"
              :value="$t('account.confirm_cancel')"
              @click="setEdit('')"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="required" class="my-4 text-center">
      {{ $t("gateways.stripe_acc_additional_verification") }}
      <a :href="link">Click here</a>
    </div>

    <button class="bttn bttn--lg bttn--blue" @click.prevent="updateStep">
      {{ $t("gateways.close_view") }}
    </button>
  </div>
  <div v-else class="text-center">
    <LdsSpinner />
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import moment from "moment-timezone";
import findIndex from "lodash/findIndex";
import { mapState } from "vuex";
import tooltip from "@/components/tooltip";
import { getAltNames } from "@/constants/gateway-alt-names";

import Multiselect from "vue-multiselect";
import { notificationsMixin } from "@/mixins";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

export default {
  props: ["gateway"],

  data: function () {
    return {
      gatewayInfo: null,
      edit: "",
      editForm: {},
      errors: [],
      required: false,
      link: "",
      testMode: false,
      chargeCurrencyListKey: "",
      loading: false,
    };
  },

  mixins: [notificationsMixin],

  components: {
    tooltip,
    Multiselect,
    LdsSpinner,
  },

  computed: mapState({
    entityName: function (state) {
      let entitiArr = state.account.entities;

      let gatewayEntityId = this.gatewayInfo.attributes.entity_id;

      let entity = entitiArr.filter((el) => {
        return el.id == gatewayEntityId;
      });

      if (entity.length > 0) {
        return entity[0];
      }

      return false;
    },

    timeZone: (state) => {
      return state.account.account.data.attributes.timezone;
    },
  }),

  methods: {
    getAltNames,

    showTestMode(field) {
      if (field === "test_mode") {
        switch (this.gatewayInfo.attributes.gateway) {
          case "stripe":
          case "stripe-element":
          case "stripe-apple-pay":
          case "stripe-google-pay":
            return true;
        }
      }
      return false;
    },

    orderedItems(items) {
      items.created_at = moment(this.gateway.attributes.created_at * 1000).format(
        "YYYY-MM-DD HH:mm"
      );

      if (this.gatewayInfo.attributes.gateway == "stripe") {
        const array = [];
        for (let key in items) {
          array.push({ [key]: items[key] });
        }
        const indexPrevItem = findIndex(array, (i) =>
          Object.prototype.hasOwnProperty.call(i, "bank_account_last_4")
        );
        const indexNextItem = findIndex(array, (i) =>
          Object.prototype.hasOwnProperty.call(i, "routing_number")
        );
        array.splice(indexPrevItem + 1, 0, array[indexNextItem]);
        const sortItems = {};
        array.map((i) => {
          sortItems[Object.keys(i)[0]] = i[Object.keys(i)[0]];
        });
        return sortItems;
      } else {
        return items;
      }
    },

    updateStep() {
      this.$emit("update-step");
    },

    setTime(date) {
      const d = date * 1000;

      if (d <= 0) {
        return this.$t("gateways.verification_due_by_not_required", "Not required");
      }

      return moment.tz(d, this.timeZone).format("YYYY-MM-DD HH:mm");
    },

    loadGatewaysData: function () {
      this.loading = true;
      let orgId = localStorage.getItem("orgId");
      axios
        .get(urls.getGatewayData.replace(":orgId", orgId).replace(":gatewayId", this.gateway.id))

        .then(({ data }) => {
          this.gatewayInfo = data.data;

          this.testMode = this.gatewayInfo.attributes.gateway_data?.test_mode === "true";

          if (this.gatewayInfo.attributes.gateway == "stripe") {
            this.getStripeOnBoarding(this.gatewayInfo.id).then(({ data }) => {
              const { required, link } = data.data.attributes;

              this.required = required;
              this.link = link;
            });
          }
        })

        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        })

        .finally(() => {
          this.loading = false;
        });
    },

    getStripeOnBoarding(id) {
      let orgId = localStorage.getItem("orgId");

      return axios.get(urls.stripeOnBoarding.replace(":oid", orgId).replace(":idGateway", id));
    },

    isEditable(key) {
      return [
        "statement_display",
        "charge_currency_list",
        "custom_label",
        "message",
        "disable_card_swiper",
      ].includes(key);
    },

    isEditableForGateway(key) {
      const keysForGateways = {
        charge_currency_list: [
          "stripe",
          "stripe-element",
          "stripe-ideal",
          "stripe_becs",
          "stripe_apple_google_pay",
        ],
      };

      if (!keysForGateways[key]) {
        return true;
      }

      return keysForGateways[key].includes(this.gatewayInfo.attributes.gateway);
    },

    isValueHTML(key) {
      return (
        ["lead_comment"].includes(key) && this.gatewayInfo.attributes.gateway.includes("meshulam")
      );
    },

    setEdit(field, value = "") {
      this.edit = field;

      if (field) {
        switch (field) {
          case "statement_display": {
            const sep = ["*", " "];
            for (let i = 0; i < sep.length; i++) {
              const s = sep[i];
              if (value.split(s)[1]) {
                this.editForm[field] = value.split(s)[1];
                break;
              }
            }
            break;
          }
          case "charge_currency_list":
            this.editForm[field] = value.split(", ");
            this.chargeCurrencyListKey = value + Math.random();
            break;
          case "custom_label":
          case "message":
            this.editForm[field] = value;
            break;

          default:
            this.editForm[field] = value;
            break;
        }
      } else {
        this.editForm = {};
      }
    },

    onChargeCurrencyListChange(value) {
      this.$set(this.editForm, "charge_currency_list", value);
      this.chargeCurrencyListKey = value.join("") + Math.random();
    },

    save() {
      let orgId = localStorage.getItem("orgId");

      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.editForm.statement_display) {
            axios
              .post(
                urls.stripeDescriptorUpdate
                  .replace(":oid", orgId)
                  .replace(":idGateway", this.gateway.id),
                {
                  statement: "Charidy " + this.editForm.statement_display,
                }
              )
              .then(() => {
                this.loadGatewaysData();
              })
              .catch(this.$_notificationsMixin_handleCatch)
              .finally(() => {
                this.setEdit("");
              });
          } else {
            axios
              .patch(
                urls.getGatewayData.replace(":orgId", orgId).replace(":gatewayId", this.gateway.id),
                { ...this.editForm }
              )
              .then(() => {
                this.loadGatewaysData();
              })
              .catch(this.$_notificationsMixin_handleCatch)
              .finally(() => {
                this.setEdit("");
              });
          }
        }
      });
    },

    testModeOffOn() {
      this.editForm["test_mode"] = !this.testMode;
      this.save();
    },

    clearDataModa: function () {
      this.gatewayInfo = null;
      this.errors = [];
    },
  },

  created() {
    this.loadGatewaysData();
  },
};
</script>

<style lang="scss">
.data-view {
  align-items: center;
}
</style>

<style lang="scss" scoped>
.data-view {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
  }
  &__title {
    min-width: 100%;
    padding: 10px 0;
    font-family: $vito-bold;
    font-size: 16px;
    @media only screen and (min-width: 992px) {
      min-width: 30%;
      max-width: 30%;
      margin-right: 20px;
    }
  }
  &__data {
    flex-grow: 1;
    padding: 10px 0;
    font-family: $vito-light;
    font-size: 16px;
    word-wrap: break-word;
    max-width: 65%;
  }
}

@media (max-width: 768px) {
  .data-view__data {
    max-width: 100%;
  }
}
.data-view-wrapper {
  button {
    float: right;
  }
}

.data-view__action {
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.edit-field {
  display: contents;
}
</style>
