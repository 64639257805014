<template>
  <div v-if="ticket" class="donation-unit" :class="{ 'donation-unit--pending': isPending }">
    <div class="donation-unit__top_row">
      <div class="donation-unit__cell donation-unit__id">
        {{ id }}
      </div>

      <div class="donation-unit__cell donation-unit__id">
        <b-button v-if="!isPending" variant="link" @click="$emit('view-donation', donationID)">
          {{ donationID }}
        </b-button>
        <span v-else>
          {{ $t("dashboard.tickets_pending", "Pending") }}
        </span>
      </div>

      <div class="donation-unit__cell donation-unit__id">
        {{ promoCode }}
      </div>

      <div class="donation-unit__cell donation-unit__name w-100">
        {{ `${titleQuantity}` }}
      </div>

      <div
        v-if="showFor(['ticket_free_price'])"
        class="donation-unit__cell donation-unit__name w-100"
      >
        {{ `${items}` }}
      </div>

      <div class="donation-unit__cell donation-unit__name w-100">
        {{ totalAmount }}
      </div>

      <div v-if="hasAccessEdit" class="donation-unit__action w-25">
        <button class="bttn bttn-round edit" @click="edit">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
        <button class="bttn bttn-round delete" @click="remove">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    ticket: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapState({
      config: (state) => state.ticket.config,
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
    }),

    usecase() {
      return this.config.usecase;
    },

    isPending() {
      return this.donationID === 0;
    },

    id() {
      return this.ticket.id;
    },

    data() {
      return this.ticket.attributes.data;
    },

    donationID() {
      return this.ticket.attributes.donation_id;
    },

    promoCode() {
      return this.data?.promo_code || "";
    },

    codeQuantityEntries() {
      return Object.entries(this.data.code_quantity)
        .filter(([k, v]) => k && Number(v))
        .map(([k, v]) => [k, Number(v)]);
    },

    titleQuantity() {
      return this.codeQuantityEntries.map(([k, v]) => {
        const data = this.getCodeData(k);
        return ` ${(data && data.title) || k} - ${v}`;
      });
    },

    items() {
      const items = this.data.items || [];

      switch (this.usecase) {
        case "ticket_free_price":
          return items.map((el) => ` ${el.name1} ${el.name2}`);

        default:
          return items.map((el) => {
            if (el.data?.length) {
              return el.data.map((e) => (e.name2 ? ` ${e.name1} ${e.name2}` : ` ${e.name1}`));
            }
            return ` ${el.name1} ${el.name2}`;
          });
      }
    },

    totalAmount() {
      switch (this.usecase) {
        case "ticket_free_price":
          return (this.data.items || []).reduce((sum, el) => {
            sum += el.amount || 0;
            return sum;
          }, 0);

        default:
          return this.codeQuantityEntries.reduce((sum, [k, q]) => {
            const data = this.getCodeData(k);
            sum += ((data && data.amount) || 0) * q;
            return sum;
          }, 0);
      }
    },
  },

  methods: {
    showFor(list = [""]) {
      return list.includes(this.usecase);
    },

    getCodeData(code) {
      return this.config.list[code];
    },

    edit() {
      this.$emit("edit", this.id);
    },

    remove() {
      this.$emit("remove", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.donation-unit__top_row {
  flex-wrap: nowrap;
}

.donation-unit--pending {
  background-color: #f5f6f8;
}

.donation-unit__action {
  transform: unset;
  position: unset;
}

.tooltip-box {
  display: inherit;
  margin-right: 10px;
}

.donation-comment {
  font-weight: bold;
  color: #414359;
  line-height: 1;
  font-size: 14px;
  padding-left: calc(10% + 40px);
  span {
    color: #bbbcc4;
    @media (max-width: 768px) {
      display: block;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
    padding-top: 20px;
  }
}
.bttn-round--gray {
  &:hover {
    background-color: darken(#bbbcc4, 15%);
  }
}
</style>
