import urls from "../../constants/urls.js";
import axios from "axios";

const module_name = "raffletab";

const getBody = (cId, data, id = "") => ({
  type: "module_data",
  id: String(id),
  attributes: {
    module: module_name,
    campaign_id: Number(cId),
    data: JSON.stringify(data),
  },
});

const state = {
  activated: false,
  config: null,
  list: [],
  count: 0,
  loading: false,
  error: null,
};

const mutations = {
  setActivated(state, value) {
    state.activated = value;
  },

  setConfig(state, value) {
    state.config = value;
  },

  setList(state, data) {
    state.list = data;
  },

  setCount(state, val) {
    state.count = val || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchTicketActivated({ commit, dispatch }, cmpId) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      module_name,
    };
    return axios
      .get(urls.getModule.replace(":oid", orgId).replace(":cid", cmpId), {
        params,
      })
      .then(({ data }) => {
        const { active } = data.data.attributes;
        commit("setActivated", active);
        if (active) {
          dispatch("fetchTicketsConfig", { campaign_id: cmpId });
        }
      })
      .catch((e) => {
        commit("setActivated", false);
        throw e;
      });
  },

  fetchTicketsConfig({ commit }, params) {
    return axios.get(urls.raffle, { params }).then(({ data }) => {
      commit("setConfig", data.data.attributes);
      return data;
    });
  },

  fetchTickets({ commit }, [cId, getParams] = []) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");

    const params = {
      module_name,
      campaign_id: cId,
      ...getParams,
    };

    axios
      .get(urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId), {
        params,
      })
      .then(({ data, headers }) => {
        commit("setCount", Number(headers["x-total-records"]));
        commit(
          "setList",
          data.data.map((el) => {
            try {
              el.attributes.data = JSON.parse(el.attributes.data);
            } catch {
              el.attributes.data = {
                code_quantity: {},
              };
            }
            return el;
          })
        );
        commit("setLoading", false);
      })
      .catch((e) => {
        commit("setError", e.response);
        commit("setLoading", false);
      });
  },

  fetchTicket(_, [cId, id, getParams] = []) {
    const orgId = localStorage.getItem("orgId");
    const sufix = String(id).trim() && `/${id}`;
    const params = { ...getParams };

    return axios
      .get(urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId) + sufix, { params })
      .then(({ data }) => {
        return {
          ...data.data,
          attributes: {
            ...data.data.attributes,
            data: JSON.parse(data.data.attributes.data),
          },
        };
      });
  },

  fetchCreateTicket(_, [cId, form, params] = []) {
    const orgId = localStorage.getItem("orgId");
    const data = getBody(cId, form);

    return axios.post(
      urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId),
      { data },
      { params }
    );
  },

  fetchUpdateTicket(_, [cId, id, form, params] = []) {
    const orgId = localStorage.getItem("orgId");
    const data = getBody(cId, form, id);

    return axios.put(
      urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId),
      { data },
      { params }
    );
  },

  fetchDeleteTicket(_, [cId, id] = []) {
    const orgId = localStorage.getItem("orgId");
    const sufix = String(id).trim() && `/${id}`;

    return axios.delete(urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId) + sufix);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
