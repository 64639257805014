<script setup>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

defineProps({
  reportName: {
    type: String,
    default: "",
  },
  chartData: {
    type: Object,
    default: () => ({}),
  },
  chartOptions: {
    type: Object,
    default: () => ({
      responsive: true,
    }),
  },
});
</script>

<template>
  <Bar
    class="tw-w-full"
    :chart-id="`chart-${reportName}`"
    dataset-id-key="label"
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>
