import urls from "../../constants/urls.js";
import axios from "axios";

import router from "@/router";

const state = {
  campaign: {},
  metas: {},
  levels: {},
  streams: {},
  roles: [],
  url_alias: [],
  campaignTemplates: [],
  emailTemplates: [],
  includedMedia: [],
  loadingCampaign: false,
  loadingEmailTemplates: false,
  liveRequest: null,
  currentlyEditedInWizard: null,
};

export function mapMetasToObject(dataIncluded) {
  const metas = {};

  if (!dataIncluded?.length) {
    return metas;
  }

  for (const inc of dataIncluded) {
    if (inc.type === "meta") {
      const { name, data } = inc.attributes;

      try {
        metas[name] = JSON.parse(data);
      } catch {
        console.table({
          error: "Invalid meta data",
          meta: name,
          data: data,
        });
      }
    }
  }

  return metas;
}

function mapDonationLevelsToObject(dataIncluded) {
  return dataIncluded.reduce((res, inc) => {
    if (inc.type === "donation_level") {
      res[inc.id] = { ...inc.attributes, id: inc.id };
    }

    return res;
  }, {});
}

function mapDonationStreamsToObject(dataIncluded) {
  return dataIncluded.reduce((res, inc) => {
    if (inc.type === "donation_stream") {
      res[inc.id] = { ...inc.attributes, id: inc.id };
    }

    return res;
  }, {});
}

function mapRolesToArray(dataIncluded) {
  const res = dataIncluded.reduce((res, inc) => {
    if (inc.type === "campaignrole") {
      res[inc.id] = { ...inc.attributes, id: inc.id };
    }

    return res;
  }, {});

  return Object.values(res).filter((r) => Boolean(r));
}

function mapUrlAliasToArray(dataIncluded) {
  const res = dataIncluded.reduce((res, inc) => {
    if (inc.type === "url_alias") {
      res[inc.id] = { ...inc.attributes, id: inc.id };
    }

    return res;
  }, {});

  return Object.values(res);
}

const mutations = {
  setCampaign(state, data) {
    state.campaign = data;
  },

  setMetas(state, data) {
    state.metas = data;
  },

  setLevels(state, data) {
    state.levels = data;
  },

  setStreams(state, data) {
    state.streams = data;
  },

  setRoles(state, data) {
    state.roles = data;
  },

  setUrlAlias(state, data) {
    state.url_alias = data;
  },

  setCampaignTemplates(state, value) {
    state.campaignTemplates = value;
  },

  setLoadingCampaign(state, value) {
    state.loadingCampaign = value;
  },

  setIncludedMedia(state, data) {
    state.includedMedia = data;
  },

  setLoadingEmailTemplates(state, value) {
    state.loadingEmailTemplates = value;
  },

  setEmailTemplates(state, data) {
    state.emailTemplates = data;
  },

  setLiveRequst(state, data) {
    state.liveRequest = data;
  },

  setCurrentlyEditedInWizard(state, data) {
    localStorage.setItem("currentlyEditedInWizard", data);
    state.currentlyEditedInWizard = data;
  },
};

const getters = {
  getSelectedCampaign(state) {
    return state.campaign.data;
  },

  getTemplateBytype: (state) => (type) => {
    if (Array.isArray(state.emailTemplates.data)) {
      return state.emailTemplates.data.filter(function (i) {
        return i.attributes.type === type;
      })[0];
    } else {
      return state.emailTemplates.data;
    }
  },

  getIncludeMediaByType: (state) => () => {
    let included = state.emailTemplates.included;

    let relationships = state.emailTemplates.data.relationships.media.data;
    const media = [];
    for (let i = 0; i < relationships.length; i++) {
      let el = included.filter((el) => {
        return el.id == relationships[i].id && el.type == relationships[i].type;
      });
      media.push(el[0]);
    }
    return media;
  },

  getIncludeCampaignMedia(state) {
    let relationships = state.campaign.data.relationships.campaign_media.data;

    let included = state.campaign.included;

    let arrMedia = [];

    for (let i = 0; i < relationships.length; i++) {
      let el = included.filter((el) => {
        return el.id == relationships[i].id && el.type == relationships[i].type;
      });

      arrMedia.push(el[0]);
    }

    return arrMedia;
  },

  getOriginalCampaignMediaValue: (_, getters) => (tag, lang) => {
    let media = getters.getIncludeCampaignMedia.filter((el) => {
      return el.attributes.tag == tag && el.attributes.lang === lang && el.attributes.team_id === 0;
    });

    return media;
  },

  yearRoundCampaign(state) {
    return state.campaign.data.attributes.template === "express";
  },

  getCurrentlyEditedInWizard(state, _0, rootState) {
    const currentlyEditedInWizard =
      localStorage.getItem("currentlyEditedInWizard") || state.currentlyEditedInWizard;

    const campaign =
      rootState.campaigns.current.find(({ id }) => id === currentlyEditedInWizard) || null;

    if (campaign) {
      return localStorage.getItem("currentlyEditedInWizard") || campaign.id;
    }

    return state.currentlyEditedInWizard;
  },
};

const actions = {
  async getCampaign({ commit }, { cid, props }) {
    commit("setLoadingCampaign", true);
    let orgId = localStorage.getItem("orgId");
    try {
      const response = await axios.get(
        urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", cid) + props
      );
      let campaign = response.data;

      commit("setCampaign", campaign);
      commit("setMetas", mapMetasToObject(campaign.included));
      commit("setLevels", mapDonationLevelsToObject(campaign.included));
      commit("setStreams", mapDonationStreamsToObject(campaign.included));
      commit("setRoles", mapRolesToArray(campaign.included));
      commit("setUrlAlias", mapUrlAliasToArray(campaign.included));

      return campaign;
    } finally {
      commit("setLoadingCampaign", false);
    }
  },

  updateCampaign({ commit, state }, props) {
    let orgId = localStorage.getItem("orgId");
    const cId = props?.cId || state.campaign.data.id || router.currentRoute.params.id;

    return axios
      .get(urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", cId) + props)
      .then((response) => {
        let campaign = response.data;

        commit("setCampaign", campaign);
        commit("setMetas", mapMetasToObject(campaign.included));
        commit("setLevels", mapDonationLevelsToObject(campaign.included));
        commit("setStreams", mapDonationStreamsToObject(campaign.included));
        commit("setRoles", mapRolesToArray(campaign.included));
        commit("setUrlAlias", mapUrlAliasToArray(campaign.included));

        return { cId: campaign.data.id };
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getEmailTemplates({ commit }) {
    let orgId = localStorage.getItem("orgId");
    commit("setLoadingEmailTemplates", true);
    axios
      .get(
        urls.getEmailTemplates
          .replace(":orgId", orgId)
          .replace(":campaignId", router.currentRoute.params.id)
      )
      .then((response) => {
        let templates = response.data;
        let included = response.data.included ? response.data.included : [];
        commit("setEmailTemplates", templates);
        commit("setLoadingEmailTemplates", false);
        commit("setIncludedMedia", included);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getCampaignTemplates({ commit, state }, props) {
    let orgId = localStorage.getItem("orgId");
    const cId = props?.cId || state.campaign.data.id || router.currentRoute.params.id;

    return axios
      .get(urls.campaignSetupTemplates.replace(":orgId", orgId).replace(":campaignId", cId))
      .then((response) => {
        let templates = response.data.data;

        if (cId == "0") {
          return templates;
        }

        commit("setCampaignTemplates", templates);
        return templates;
      })
      .catch((err) => console.log(err));
  },

  updateCampaignData({ commit, state }, props) {
    let orgId = localStorage.getItem("orgId");
    const params = `?${[
      "extend=campaign_stats",
      "extend=content",
      "extend=media",
      "extend=matchers",
      "extend=donation_levels",
      "extend=donation_streams",
    ].join("&")}`;
    const cId = props?.cId || state.campaign.data.id || router.currentRoute.params.id;

    return axios
      .get(urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", cId) + params)
      .then((response) => {
        let campaign = response.data;

        commit("setCampaign", campaign);
        commit("setMetas", mapMetasToObject(campaign.included));
        commit("setLevels", mapDonationLevelsToObject(campaign.included));
        commit("setStreams", mapDonationStreamsToObject(campaign.included));
        commit("setRoles", mapRolesToArray(campaign.included));
        commit("setUrlAlias", mapUrlAliasToArray(campaign.included));

        return { cId: campaign.data.id };
      })
      .catch((e) => {
        console.log(e);
      });
  },

  fetchLiveRequest({ commit }, [cId, getParams = {}] = []) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      ...getParams,
    };

    return axios
      .get(urls.liveRequest.replace(":oid", orgId).replace(":cid", cId), {
        params,
      })
      .then(({ data }) => {
        commit("setLiveRequst", data.data);
        return data;
      })
      .catch(({ response }) => {
        if (response.status === 404) {
          commit("setLiveRequst", null);
        }
      });
  },

  fetchCreateLiveRequest(_, [cId, id, attr = {}, getParams = {}] = []) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      ...getParams,
    };
    const body = {
      data: {
        type: "campaign_live_request",
        id,
        attributes: {
          campaign_id: Number(cId),
          ...attr,
        },
      },
    };

    return axios
      .post(urls.liveRequest.replace(":oid", orgId).replace(":cid", cId), body, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  },

  featchValidationShortLink(_, { cId, shortLink }) {
    const orgId = localStorage.getItem("orgId");

    return axios
      .get(
        urls.validationShortLink
          .replace(":orgId", orgId)
          .replace(":campaignId", cId)
          .replace(":exclude_campaign_id", cId)
          .replace(":shortlink", shortLink)
      )
      .then(({ data }) => {
        return data;
      });
  },

  fetchSaveDescription({ dispatch }, { cId, arrDesc, noUpdates }) {
    const orgId = localStorage.getItem("orgId");

    return Promise.all(
      arrDesc.map((desc) => {
        if (!desc?.id) {
          return axios.post(
            `${urls.addDescription.replace(":orgId", orgId).replace(":campaignId", cId)}`,
            { data: desc }
          );
        } else {
          return axios.put(
            `${urls.addDescription.replace(":orgId", orgId).replace(":campaignId", cId)}/${
              desc.id
            }`,
            { data: desc }
          );
        }
      })
    ).then(() => {
      !noUpdates && dispatch("updateCampaignData", { cId });
    });
  },

  fetchAddNewDescriptionLang({ state }, { cId, arrDesc }) {
    const orgId = localStorage.getItem("orgId");
    const cIdLocal = cId || state.campaign.data.id;

    return Promise.all(
      arrDesc.map((desc) =>
        axios.post(urls.addDescription.replace(":orgId", orgId).replace(":campaignId", cIdLocal), {
          data: desc,
        })
      )
    ).then(({ data }) => ({ cId: cIdLocal, data }));
  },

  fetchEditCampaign({ dispatch, state }, { cId, data, params }) {
    const orgId = localStorage.getItem("orgId");
    const cIdLocal = cId || state.campaign.data.id;
    const update = params?.update || false;
    const cmpAtrs = state.campaign?.data?.attributes;
    const { gateway_id_list, ...restData } = data;
    const gatewayIdListOr = gateway_id_list || [];

    if (!cmpAtrs) {
      return;
    }

    const mapped = {
      data: {
        attributes: {
          ...cmpAtrs,
          primary_goal_multiplier: cmpAtrs.rounds[0].multiplier,
          ...restData,
        },
        relationships: {
          gateway_id_list: {
            data: gatewayIdListOr.map((id) => ({
              id: id.toString(),
              type: "gateway_id",
            })),
          },
        },
      },
      included: [
        ...gatewayIdListOr.map((id) => ({
          id: id.toString(),
          type: "gateway_id",
          attributes: {},
        })),
      ],
    };
    return axios
      .put(urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", cIdLocal), mapped)
      .then(({ data }) => {
        update && dispatch("updateCampaignData", { cId: cIdLocal });
        return { cId: cIdLocal, data };
      });
  },

  fetchSaveTemplate({ dispatch, state }, { cId, templateCode, params }) {
    const data = {
      data: {
        attributes: {
          code: templateCode,
        },
      },
    };
    const orgId = localStorage.getItem("orgId");
    const update = params?.update || false;
    const extendReturn = params?.extendReturn || {};
    const cIdLocal = cId || state.campaign.data.id;

    return axios
      .post(
        urls.campaignSetupTemplate.replace(":orgId", orgId).replace(":campaignId", cIdLocal),
        data
      )
      .then(() => {
        update && dispatch("updateCampaignData", { cId: cIdLocal });
        return { cId: cIdLocal, ...extendReturn };
      });
  },

  fetchDeleteCampaign({ commit }, { cId }) {
    const orgId = localStorage.getItem("orgId");
    return axios
      .delete(urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", cId))
      .then(({ data }) => data)
      .then((data) => {
        commit("setCampaign", {});
        commit("setMetas", []);
        commit("setLevels", []);
        commit("setStreams", []);
        commit("setRoles", []);
        commit("setUrlAlias", []);
        commit("setCurrentlyEditedInWizard", null);

        return data;
      });
  },

  fetchCreateCampaign(_, { title, shortLink, currency, startDate, endDate, gatewayIdList = [] }) {
    const orgId = localStorage.getItem("orgId");
    const mapped = {
      data: {
        attributes: {
          title,
          short_link: shortLink,
          bonus_goal: 0,
          currency,
          start_date: startDate,
          end_date: endDate,
        },
        relationships: {
          gateway_id_list: {
            data: gatewayIdList.map((id) => ({
              id: id.toString(),
              type: "gateway_id",
            })),
          },
        },
      },
      included: [
        ...gatewayIdList.map((id) => ({
          id: id.toString(),
          type: "gateway_id",
          attributes: {},
        })),
      ],
    };
    return axios
      .post(urls.getCampaignList.replace(":orgId", orgId), mapped)
      .then(({ data: { data } }) => ({
        cId: data.id,
      }));
  },

  async fetchCreateCampaignWizard(
    { dispatch, commit },
    {
      title,
      shortLink,
      primaryGoal,
      primaryGoalMultiplier,
      currency,
      startDate,
      endDate,
      templateCode,
    }
  ) {
    const validStartDate =
      startDate < new Date().getTime() / 1000
        ? (new Date().getTime() + 24 * 60 * 60 * 1000) / 1000
        : startDate;

    try {
      const { cId } = await dispatch("fetchCreateCampaign", {
        title,
        shortLink,
        primaryGoal,
        currency,
        startDate: validStartDate,
        endDate,
      });
      await dispatch("updateCampaignData", { cId });

      const promises = await Promise.all([
        dispatch("fetchSaveTemplate", {
          cId,
          templateCode,
        }),
        dispatch("fetchEditCampaign", {
          cId,
          data: {
            primary_goal: primaryGoal,
            primary_goal_multiplier: primaryGoalMultiplier,
          },
        }),
        dispatch("updateCampaignData", { cId }),
        commit("setCurrentlyEditedInWizard", cId),
        cId,
      ]).catch((e) => {
        dispatch("fetchDeleteCampaign", { cId });
        return Promise.reject(e);
      });

      return new Promise((resolve) => resolve(Object.assign({}, ...promises)));
    } catch (e) {
      return new Promise((_, reject) => reject(e));
    }
  },

  fetchCreateMediaFile({ state }, { cId, data: { src, tag, lang } }) {
    const orgId = localStorage.getItem("orgId");
    const cIdLocal = cId || state.campaign.data.id;
    const mediaFile = {
      data: {
        attributes: {
          campaign_id: parseInt(cIdLocal),
          src,
          tag,
          order: 1,
          lang,
        },
      },
    };

    return axios
      .post(urls.loadImage.replace(":orgId", orgId).replace(":campaignId", cIdLocal), mediaFile)
      .then(({ data }) => ({ cId: cIdLocal, data }));
  },

  fetchEditMediaFile({ state, dispatch }, { id, cId, data }) {
    if (data.src.length === 0) {
      return dispatch("fetchDeleteMediaFile", { id, cId });
    }

    const orgId = localStorage.getItem("orgId");
    const cIdLocal = cId || state.campaign.data.id;
    const mediaFile = {
      data: {
        attributes: {
          campaign_id: parseInt(cIdLocal),
          ...data,
        },
      },
    };

    return axios
      .put(
        `${urls.loadImage.replace(":orgId", orgId).replace(":campaignId", cIdLocal)}/${id}`,
        mediaFile
      )
      .then(({ data }) => ({ cId: cIdLocal, data }));
  },

  fetchDeleteMediaFile({ state }, { id, cId }) {
    const orgId = localStorage.getItem("orgId");
    const cIdLocal = cId || state.campaign.data.id;

    return axios
      .delete(`${urls.loadImage.replace(":orgId", orgId).replace(":campaignId", cIdLocal)}/${id}`)
      .then(({ data }) => ({ cId: cIdLocal, data }));
  },

  fetchLoadWizardMedia(context, { cId, videos }) {
    const { dispatch, getters, state } = context;
    const cIdLocal = cId || state.campaign.data.id;

    const normalizeSrc = (src) => (src.startsWith("/") ? src.substr(1) : src);

    const loadVideo = ({ tag, src, lang }) => {
      const originalValue = getters.getOriginalCampaignMediaValue(tag, lang);

      if (!originalValue.length && src === "") {
        // Do nothing.
        return Promise.resolve();
      }

      if (originalValue.length && src === "") {
        let [video] = originalValue;

        return dispatch("fetchDeleteMediaFile", {
          id: video.id,
          cId: cIdLocal,
        });
      }

      if (originalValue.length) {
        let [video] = originalValue;
        video.attributes.src = normalizeSrc(src);

        return dispatch("fetchEditMediaFile", {
          id: video.id,
          cId: cIdLocal,
          data: video.attributes,
        });
      }

      return dispatch("fetchCreateMediaFile", {
        cId: cIdLocal,
        data: {
          tag,
          src: normalizeSrc(src),
          lang,
        },
      });
    };

    return Promise.all(videos.map((video) => loadVideo(video)));
  },

  async fetchReceiptBulk(_, [cId, getParams] = []) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      ...getParams,
      campaign_id: cId,
    };

    const { data } = await axios.get(urls.receiptBulk(orgId, cId), {
      params,
    });
    return data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
