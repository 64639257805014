<template>
  <b-overlay class="tw-col-span-3" :show="loading">
    <b-row class="py-4">
      <b-col cols="12">
        <router-link to="/donor-my-teams">
          <b-icon icon="arrow-left-square" />
          {{ $t("donor_dashboard.back_to_my_teams", "Back to team list") }}
        </router-link>
      </b-col>
    </b-row>

    <b-alert v-for="(err, i) in errors" :key="i" show variant="danger">
      {{ err.detail }}
    </b-alert>

    <b-row v-if="donorTeam">
      <b-col class="my-3" cols="12">
        <teams-card-big
          :team="donorTeam.team"
          :campaign="donorTeam.campaign"
          :customFields="donorTeam.team_custom_fields || {}"
          hideDescription
          @click-edit-team="openTeamForm(donorTeam.team, donorTeam.login_to_team.permissions)"
        />
      </b-col>

      <b-col class="my-3" cols="12">
        <team-stats-deck
          :goal="donorTeam.team.goal"
          :currency="donorTeam.campaign && donorTeam.campaign.currency"
          :teamStats="donorTeam.team_stats"
        />
      </b-col>

      <b-col class="my-3">
        <EmailMsgModal #default="{ open: openEmailMsgModal, sending }">
          <DonorsProvider
            :teamId="donorTeam.team.id"
            :currency="donorTeam.campaign && donorTeam.campaign.currency"
            @update:checkedDonors="(v) => (checkedDonors = v)"
          >
            <template #table="{ donors, fields, loading, internalCheckedDonors }">
              <portal to="donors-table">
                <DonorsTable
                  :donors="donors"
                  :fields="fields"
                  :loading="loading"
                  :checkedDonors="internalCheckedDonors.get()"
                  @update:checkedDonors="internalCheckedDonors.update"
                  @send-email="({ donor }) => openEmailMsgModal({ donor, team: donorTeam.team })"
                />
              </portal>
            </template>
            <template #pagination="{ page, updatePage, total, perPage }">
              <portal to="donors-table-pagination">
                <DonorsTablePagination
                  :value="page"
                  @input="updatePage"
                  :total="total"
                  :perPage="perPage"
                  class="m-0 justify-content-start"
                />
              </portal>
            </template>
          </DonorsProvider>

          <b-card class="shadow-sm rounded" footer-class="p-1" no-body>
            <b-tabs
              card
              content-class="p-0"
              :value="currentTabIndex"
              @input="(tabIndex) => (currentTabIndex = tabIndex)"
            >
              <b-tab :title="$t('donor_dashboard.team_donors_tab', 'Donors')" active lazy no-body>
                <portal-target name="donors-table" />
              </b-tab>
              <b-tab
                v-if="
                  donorTeam.team_report &&
                  donorTeam.team_report.protected_team_past_campaign_donors_outreach
                "
                :title="
                  $t(
                    'donor_dashboard.team_report_protected_team_past_campaign_donors_outreach_tab',
                    'Past Campaign Donors Outreach Report'
                  )
                "
                lazy
                no-body
              >
                <ReportProvider
                  :teamId="donorTeam.team.id"
                  reportName="protected_team_past_campaign_donors_outreach"
                  :reportParams="{
                    campaign_id: donorTeam.campaign.campaign_id,
                    past_campaign_id: [],
                    compare_by: 'email',
                  }"
                  v-slot="{
                    getProtectedDonorReport,
                    generateFileProtectedDonorReport,
                    computedReportParams,
                    setReportParams,
                    loadingAction,
                    page,
                    perPage,
                    setPagination,
                    total,
                    list,
                    loading,
                    checkDuplicatesOptions,
                    loadersCanBeUnlocked,
                  }"
                  class="container-fluid"
                >
                  <ReportPastCampaignDonorsOutreach
                    :getProtectedDonorReport="getProtectedDonorReport"
                    :generateFileProtectedDonorReport="generateFileProtectedDonorReport"
                    :computedReportParams="computedReportParams"
                    :setReportParams="setReportParams"
                    :loadingAction="loadingAction"
                    :page="page"
                    :perPage="perPage"
                    :setPagination="setPagination"
                    :total="total"
                    :list="list"
                    :loading="loading"
                    :teamId="donorTeam.team.id"
                    :checkDuplicatesOptions="checkDuplicatesOptions"
                    :loadersCanBeUnlocked="loadersCanBeUnlocked"
                  />
                </ReportProvider>
              </b-tab>
            </b-tabs>
            <template #footer>
              <div
                v-if="currentTabIndex === 0"
                id="donors-table-footer"
                class="d-flex flex-row flex-wrap align-items-center justify-content-between"
              >
                <portal-target name="donors-table-pagination" />
                <div class="d-flex flex-row align-items-center justify-content-around">
                  <b-button
                    v-if="checkedDonors.length > 0"
                    class="mx-1 bttn bttn--sm bttn--orange text-nowrap"
                    @click="openEmailMsgModal({ donor: checkedDonors, team: donorTeam.team })"
                    :disabled="sending"
                  >
                    <b-spinner class="mr-2" v-if="sending" small></b-spinner>
                    {{
                      $t(
                        "donor_dashboard.send_email_multiple_donors",
                        "Send to {donors} donor ::: Send to {donors} donors",
                        { donors: checkedDonors.length },
                        checkedDonors.length
                      )
                    }}
                  </b-button>
                  <b-button
                    class="mx-1 bttn bttn--sm bttn--orange text-nowrap"
                    @click="openEmailMsgModal({ donor: { id: -1 }, team: donorTeam.team })"
                    :disabled="sending"
                  >
                    <b-spinner class="mr-2" v-if="sending" small></b-spinner>
                    {{ $t("donor_dashboard.send_email_all_donors", "Email All Donors") }}
                  </b-button>
                  <ExportAllDonors @proc-request-finished="onExportAllDonorsFinished">
                    <template #default="{ exportDonors, regenerating }">
                      <b-button
                        class="mx-1 bttn bttn--sm bttn--orange text-nowrap"
                        @click="exportDonors($route.params.id)"
                        :disabled="regenerating"
                      >
                        <b-spinner class="mr-2" v-if="regenerating" small></b-spinner>
                        {{ $t("donor_dashboard.export_donors", "Export Donors") }}
                      </b-button>
                    </template>
                  </ExportAllDonors>
                </div>
              </div>
              <div
                v-if="currentTabIndex === 1"
                id="report-past-campaign-donors-outreach-footer"
                class="d-flex flex-row flex-wrap align-items-center justify-content-between"
              >
                <portal-target name="report-past-campaign-donors-outreach-pagination" />
                <div class="ml-auto d-flex flex-row align-items-center justify-content-around">
                  <portal-target name="report-past-campaign-donors-outreach-actions" />
                </div>
              </div>
            </template>
          </b-card>
        </EmailMsgModal>
      </b-col>
    </b-row>
    <team-form ref="TeamForm" />
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import teamsCardBig from "./subcomponents/teams-card-big.vue";
import teamForm from "./subcomponents/team-form.vue";
import DonorsProvider from "./team-info-page/donors-provider.vue";
import DonorsTable from "./team-info-page/donors-table.vue";
import DonorsTablePagination from "./team-info-page/donors-table-pagination.vue";
import EmailMsgModal from "./email-messaging/emailMsgModal.vue";
import TeamStatsDeck from "./team-info-page/team-stats-deck.vue";
import ExportAllDonors from "./team-info-page/export-all-donors.vue";
import ReportProvider from "./reports/report-provider.vue";
import ReportPastCampaignDonorsOutreach from "./reports/report-past-campaign-donors-outreach.vue";

export default {
  components: {
    teamsCardBig,
    teamForm,
    DonorsProvider,
    DonorsTable,
    DonorsTablePagination,
    EmailMsgModal,
    TeamStatsDeck,
    ExportAllDonors,
    ReportProvider,
    ReportPastCampaignDonorsOutreach,
  },
  data() {
    return {
      donorTeam: null,
      checkedDonors: [],
      errors: [],
      loading: false,
      currentTabIndex: 0,
    };
  },
  mounted() {
    this.getTeamData();
  },
  watch: {
    donorTeam(val) {
      if (val?.campaign?.campaign_id) {
        const p = {
          cId: val?.campaign?.campaign_id,
        };
        this.fetchIsActive(p);
      }
    },
  },
  methods: {
    ...mapActions({
      getDonorTeam: "donorTeams/getDonorTeam",
      fetchIsActive: "seferTorahPages/fetchIsActive",
    }),
    getTeamData() {
      this.loading = true;
      const id = this.$route.params.id;
      this.getDonorTeam({
        id,
        params: {
          extend: [
            "stats",
            "campaign",
            "organization",
            "permissions",
            "team_report",
            "team_custom_fields",
          ],
        },
      })
        .then((data) => {
          this.donorTeam = data;
        })
        .catch((err) => {
          const resp = err?.response;
          this.errors = resp?.data?.errors || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openTeamForm(team, permissions) {
      this.$refs.TeamForm.openEditModal(team, permissions);
    },
    onExportAllDonorsFinished(attrs) {
      const a = document.createElement("a");
      this.$el.appendChild(a);
      a.download = attrs.title;
      a.href = attrs.meta.file;
      a.click();
      this.$el.removeChild(a);
    },
  },
};
</script>

<style lang="scss" scoped></style>
