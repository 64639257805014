<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    as: {
      type: String,
      default: "div",
    },
  },
  methods: {
    confirm() {
      this.$bvModal
        .msgBoxConfirm(this.title, {
          title: this.$t("edit_campaign.confirm_modal_title", "Please Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("account.confirm_yes", "Yes"),
          cancelTitle: this.$t("account.confirm_cancel", "NO"),
          footerClass: "p-2",
          headerClass: "msg-box-confirm__header",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("confirm");
          } else {
            this.$emit("cancel");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
});
</script>

<template>
  <component v-bind="$attrs" v-on="$listeners" :is="as" @click="confirm">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
.msg-box-confirm {
  ::v-deep &__header {
    .close {
      [dir="ltr"] & {
        margin: -1rem -1rem -1rem auto;
      }
      [dir="rtl"] & {
        margin: -1rem auto -1rem -1rem;
      }
    }
  }
}
</style>
