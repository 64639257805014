<template>
  <b-modal id="donationModal" size="lg" hide-footer hide-header no-close-on-backdrop>
    <button type="button" class="close" name="button" @click="onCloseButtonClick">
      <i class="fa fa-times" aria-hidden="true" />
    </button>
    <iframe
      id="donationsIframeSrr"
      ref="donationsIframeSrr"
      name="donationsIframeSrr"
      title="Donations Form Iframe in Modal"
      allowpaymentrequest
      allow="payment *"
      :src="link"
      @load="setCustomStyle"
    />
  </b-modal>
</template>

<script>
const customStyle = `#modal-donate-success {
  padding: 0px;
  width: 100%;
  height: 100%;
  background: #fff;
}
#modal-donate-success .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
}
#modal-donate-success .modal-content {
  border: 0;
  box-shadow: none;
}
#modal-donate-success .modal-body {
  background: #fff;
}
#modal-donate-success .close {
  display: none;
}

#modal-donate-success .btn-return-to-campaign {
  display: none;
}

.donate-close {
  display: none;
}

.page-donation {
  background: #fff;
  padding: 25px !important;
}

.page-campaign > .container {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

header {
  display: none !important;
}`;

export default {
  model: {
    prop: "link",
    event: "setUrlDonate",
  },

  props: {
    link: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      linkCopy: "",
      registeredBeforeUnload: false,
    };
  },

  watch: {
    link(v) {
      if (v) {
        this.openModal();
      }
    },
  },

  mounted() {
    if (this.link) {
      this.openModal();
    } else {
      this.closeModal();
    }

    window.addEventListener("message", (msg) => {
      if (msg.data && msg.data.type) {
        if (msg.data.type === "redirect-in-parent") {
          window.location.href = msg.data.payload.url;
        }

        if (msg.data.type === "return-to-campaign") {
          this.closeModal();
        }

        if (msg.data.type === "registered-before-unload") {
          this.registeredBeforeUnload = true;
        }

        if (msg.data.type === "unload-donation-form") {
          // if we have registered beforeunload event and use clicked confirm
          // unload event should trigger
          if (!this.registeredBeforeUnload) {
            return;
          }

          this.closeModal();
        }

        if (msg.data.type === "gateway-response-success") {
          this.$emit("gateway-response-success", msg.data.payload);
          this.closeModal();
        }

        if (msg.data.type === "gateway-response-error") {
          this.$emit("gateway-response-error");
        }
      }
    });
  },

  methods: {
    openModal() {
      this.$root.$emit("bv::show::modal", "donationModal");
    },

    closeModal() {
      this.$root.$emit("bv::hide::modal", "donationModal");
      this.linkCopy = "";
      this.$emit("setUrlDonate", "");
    },

    onCloseButtonClick() {
      if (this.registeredBeforeUnload) {
        // triger beforeunload event
        // we need to save link one time for beforeunload event flow
        if (this.linkCopy.length === 0) {
          this.linkCopy = this.link;
          this.$emit("setUrlDonate", "");
        } else {
          const link = this.linkCopy;
          this.linkCopy = "";
          this.$emit("setUrlDonate", link);
        }
        return;
      }

      this.closeModal();
    },

    setCustomStyle() {
      const iframeOrigin = this.link && new URL(this.link).host;
      const pageOrigin = location.host.replace("www.", "");
      const styleCss = document.createElement("style");
      styleCss.rel = "stylesheet";
      styleCss.type = "text/css";
      styleCss.appendChild(document.createTextNode(customStyle));
      if (frames.donationsIframeSrr && iframeOrigin === pageOrigin) {
        frames.donationsIframeSrr.document.head.appendChild(styleCss);
      }
    },
  },
};
</script>

<style lang="scss">
.he,
.ar {
  #donationModal {
    .close {
      left: -13px;
      right: auto;
    }
  }
}

#donationModal {
  .modal-dialog {
    max-width: 814px !important;
  }
  .close {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    align-items: 26px;
    background: #ca6d50;
    opacity: 1;
    font-size: 18px;
  }
  iframe {
    width: 100%;
    height: 90vh;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .modal-body {
    padding: 0;
  }
}
</style>
