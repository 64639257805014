<template>
  <div v-if="hasAccessAdd && withCID">
    <button
      class="tw-w-full tw-flex tw-text-start tw-p-[15px] tw-ps-[60px] custom-xl:tw-ps-[40px] tw-rounded-r-[50px] tw-bg-[#50cdaa] tw-border-[#3CC7A0] tw-text-[#313131] tw-outline-none tw-text-[16px] tw-shadow-lg tw-border hover:tw-font-vito-bold hover:tw-no-underline"
      @click="open"
    >
      {{ $t("dashboard.add_button_with_receipt_title", "Add offline donation with receipt") }}
    </button>

    <donation-add-modal
      v-if="cmp"
      ref="addDonation"
      :campaignid="cmp.id"
      :ccy="cmp.attributes.currency"
      hideAddBtn
      withReceipt
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import donationAddModal from "@/views/dashboard/subcomponents/donationAddModal.vue";
import urls from "@/constants/urls";

export default {
  components: { donationAddModal },

  data() {
    return {
      cmp: null,
    };
  },

  computed: {
    ...mapState({
      hasAccessAdd: (state) => state.account.showCreateOfflineDonationWithReceiptBtn,
      withCID: (state) => state.account.showCreateOfflineDonationWithReceiptCID,
    }),
  },

  methods: {
    open() {
      const orgId = localStorage.getItem("orgId");

      axios
        .get(urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", this.withCID))
        .then(({ data }) => {
          this.cmp = data.data;

          this.$store.commit("setSelectedCampaign", this.withCID);

          this.getCampaignData();

          this.$store.dispatch("getTeamsByCampaignId", [this.withCID]);

          this.$nextTick(() => {
            this.$refs.addDonation.openModal();
          });
        });
    },

    getCampaignData() {
      let params = {
        id: this.withCID,
        setting: `?extend=campaign_stats&extend=content&extend=media&extend=meta&extend=matchers`,
      };

      this.$store.dispatch("getCampaignByIdDashboard", params);

      this.$store.dispatch("gatewaysForFilterByCampaign", this.withCID);
    },
  },
};
</script>
