import { render, staticRenderFns } from "./stripe-gateways-links.vue?vue&type=template&id=10aa8211&scoped=true&"
import script from "./stripe-gateways-links.vue?vue&type=script&lang=js&"
export * from "./stripe-gateways-links.vue?vue&type=script&lang=js&"
import style0 from "./stripe-gateways-links.vue?vue&type=style&index=0&id=10aa8211&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10aa8211",
  null
  
)

export default component.exports