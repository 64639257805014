<template>
  <div>
    <button class="bttn bttn--sm bttn--orange" :disabled="!hasAccess" @click="openModal">
      {{ $t("edit_campaign.add_new_matcher") }}
    </button>
    <!-- Modal Component -->
    <b-modal id="team-form" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>
          {{ $t("edit_campaign.matcher_form") }}
        </h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("edit_campaign.matcher_form_close") }}
        </button>
        <button
          type="submit"
          form="matcher-form"
          :disabled="sending"
          :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        >
          {{ $t("edit_campaign.matcher_form_save") }}
        </button>
      </div>

      <form id="matcher-form" action="" class="gateway-form" @submit.prevent="saveForm">
        <error v-if="errors" :errors="errors"></error>

        <div class="form-block">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="matcher-card__img">
                <div class="matcher-img">
                  <label v-if="!matcherData.data.attributes.image" @click="changeImage()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                      />
                    </svg>
                    {{ $t("edit_campaign.upload_matcher_image") }}
                  </label>

                  <div v-if="matcherData.data.attributes.image" class="matcher-img__loaded">
                    <div class="image-wrapper">
                      <img :src="matcherData.data.attributes.image" alt="" />
                    </div>
                    <div class="matcher-img__action matcher-img__delete" @click="deleteImage()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </div>
                    <label class="matcher-img__action matcher-img__change" @click="changeImage()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-9">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <label
                      class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                      >{{ $t("edit_campaign.matcher_name") }}</label
                    >
                    <input
                      type="text"
                      required
                      class="form-control form-unit__input"
                      v-model="matcherData.data.attributes.name"
                    />
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <b-form-group
                        label-for="entities-type"
                        class="account-card__select entities-data__labels"
                      >
                        <b-form-select
                          id="entities-type"
                          v-model="matcherData.data.attributes.goal"
                          :options="matcherType"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group
                        label-for="matcher-theme"
                        class="account-card__select entities-data__labels"
                      >
                        <b-form-select
                          id="matcher-theme"
                          v-model="matcherData.data.attributes.tag"
                          :options="templateType"
                        >
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group form-unit">
                        <label
                          class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                        >
                          {{ $t("edit_campaign.matcher_order") }}
                        </label>
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          required
                          class="form-control form-unit__input"
                          v-model.number="matcherData.data.attributes.order"
                        />
                      </div>
                    </div>
                    <div class="col-6 align-self-end">
                      <div class="form-checkbox form-checkbox--orange">
                        <input
                          type="checkbox"
                          v-model="matcherData.data.attributes.featured"
                          id="v1"
                          class="form-checkbox__input"
                        />
                        <label for="v1" class="form-checkbox__label">
                          {{ $t("edit_campaign.featured_matcher_label") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group form-unit">
                <label class="form-unit__label form-unit__label--upper-sm">{{
                  $t("edit_campaign.matcher_description")
                }}</label>
                <quill-editor
                  v-model="matcherData.data.attributes.description"
                  :enable="hasAccess"
                  id="quill-editor"
                  ref="myQuillEditor"
                  :options="editorOption"
                >
                </quill-editor>
              </div>
            </div>
          </div>
        </div>

        <div class="gateway-form__action"></div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { unwrapContentInline } from "@/components/vue-quill-editor/utils";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";

export default {
  data: function () {
    return {
      edit: false,

      errors: [],

      sending: false,

      matcherId: 0,

      templateType: [
        { value: "", text: this.$t("edit_campaign.matcher_type_default") },
        { value: "host", text: this.$t("edit_campaign.matcher_type_hosts") },
      ],

      matcherType: [
        { value: 0, text: this.$t("edit_campaign.matcher_type_0") },
        { value: 1, text: this.$t("edit_campaign.matcher_type_1") },
      ],

      matcherData: {
        data: {
          attributes: {
            active: true,
            campaign_id: 0,
            description: "",
            featured: false,
            goal: 0,
            image: "",
            name: "",
            order: 1,
            tag: "",
          },
        },
      },

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              [CustomVideo.blotName],
              ["link"],
              ["clean"],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      },
    };
  },

  components: {
    error: error,
  },

  computed: mapState({
    campaignId: (state) => {
      return state.campaign.campaign.data.id;
    },

    hasAccess: (state) => {
      return state.account.access.CanEditCampaignMatchers;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },
  }),

  methods: {
    openModal: function () {
      this.$root.$emit("bv::show::modal", "team-form");
      this.matcherData.data.attributes.campaign_id = parseInt(this.campaignId);
    },

    openModalEdit: function (matcher) {
      this.edit = true;
      this.matcherData.data.attributes = matcher.attributes;

      this.matcherId = matcher.id;

      this.openModal();
    },

    clearData: function () {
      this.edit = false;

      this.matcherId = 0;

      this.matcherData.data.attributes = {
        active: true,
        campaign_id: parseInt(this.campaignId),
        description: "",
        featured: false,
        goal: 0,
        image: "",
        name: "",
        order: 1,
        tag: "",
      };
    },

    modalClose: function () {
      this.$root.$emit("bv::hide::modal", "team-form");
    },

    saveForm: function () {
      this.matcherData.data.attributes.description = unwrapContentInline(
        this.matcherData.data.attributes.description
      );

      if (this.edit) {
        this.editMatcher();
      } else {
        this.addMatcher();
      }
    },

    addMatcher: function () {
      this.true = false;

      this.errors = [];

      axios
        .post(
          urls.apiMathcers.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId),
          this.matcherData
        )
        .then(() => {
          this.sending = false;

          let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";

          this.$store.dispatch("updateCampaign", extend);

          this.modalClose();

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_matchers"),
          });
        })

        .catch((e) => {
          this.sending = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    editMatcher: function () {
      this.sending = true;

      this.errors = [];

      axios
        .put(
          urls.apiPutMacther.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId) +
            "/" +
            this.matcherId,
          this.matcherData
        )
        .then(() => {
          this.sending = false;

          this.modalClose();

          let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";

          this.$store.dispatch("updateCampaign", extend);

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_matchers"),
          });
        })

        .catch((e) => {
          this.sending = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    deleteImage: function () {
      this.matcherData.data.attributes.image = "";
    },

    changeImage: function () {
      this.errors = [];

      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("sending-image-input");

      fileInput.addEventListener("change", () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          let form_data = new FormData();

          form_data.append("file", fileInput.files[0]);

          let orgId = localStorage.getItem("orgId");

          axios
            .post(urls.loadImageQuill.replace(":OrgId", orgId), form_data)
            .then((response) => {
              this.matcherData.data.attributes.image = response.data.src;

              fileInput.remove();
            })
            .catch((e) => {
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
    },
  },
};
</script>

<style media="screen">
.quill-editor {
  margin-bottom: 30px;
}
.quill-editor .ql-container {
  height: 200px;
  position: relative;
}
</style>
