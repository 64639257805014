<template>
  <form action="" class="campaign-groups">
    <div class="row">
      <div class="col-xs-12 text-right">
        <a class="btn-add" role="button" @click="addGroup">+ Add group</a>
      </div>
    </div>

    <div class="row" v-for="index in groupCount" :key="index">
      <div class="col-xs-12 col-md-8">
        <app-campaign-group-input></app-campaign-group-input>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 text-right">
        <input type="submit" class="submit-form" value="Save" />
      </div>
    </div>
  </form>
</template>

<script>
import campaignGroupInput from "./campaignGroupInput.vue";

export default {
  components: {
    appCampaignGroupInput: campaignGroupInput,
  },
  data() {
    return {
      groupCount: 0,
    };
  },
  methods: {
    addGroup() {
      this.groupCount += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add {
  margin-bottom: 20px;
}
.submit-form {
  margin-right: 0;
  margin-left: auto;
}
</style>
