<script>
import { useCampaignSettings } from "@/composables/campaign-settings";
import { notificationsMixin } from "@/mixins";
import { isAxiosError } from "axios";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  SETTING_NAME: "add_set_campaign_mode_setting",

  mixins: [notificationsMixin],
  setup() {
    const { createSetting, updateSetting, getSetting } = useCampaignSettings();

    return {
      createSetting,
      updateSetting,
      getSetting,
    };
  },
  data() {
    return {
      loading: false,
      form: {
        campaign_mode: null,
      },
      campaignModeOptions: [
        {
          text: this.$t("edit_campaign.campaign_modes_live", "Campaign is LIVE"),
          value: 2,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_countdown", "Campaign is in COUNTDOWN mode"),
          value: 1,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_over", "Campaign is over"),
          value: 3,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_demo", "Campaign is in DEMO mode"),
          value: 5,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_pending", "Campaign is in PENDING mode"),
          value: 0,
          disabled: true,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_failed", "Campaign is in FAILED mode"),
          value: 4,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      oID: () => localStorage.getItem("orgId"),
      cID: (state) => state.campaign.campaign.data.id,
      addSetCampaignModeSetting: (state) => state.account.addSetCampaignModeSetting,
      addSetCampaignModeSettingMeta: (s) =>
        s.campaign.metas?.add_set_campaign_mode_setting?.value || false,
    }),
  },
  methods: {
    async wrapGetSetting() {
      try {
        const setting = await this.getSetting(
          {
            cid: this.cID,
            oid: this.oID,
            extraParams: {
              falback_to_meta_dir: 1,
            },
          },
          this.$options.SETTING_NAME
        );

        if (!setting) {
          throw new Error(
            this.$t("edit_campaign.setting_get_error", "Cannot fetch setting: {name}", {
              name: this.$options.SETTING_NAME,
            })
          );
        }

        return setting;
      } catch (e) {
        if (isAxiosError(e)) {
          this.$_notificationsMixin_handleCatch(e);
        } else if (e instanceof Error) {
          this.$_notificationsMixin_makeToast("Error", e.message, "danger");
        }

        console.error(e);
        throw e;
      }
    },
    async wrapAddSetting(form) {
      try {
        const setting = await this.createSetting(
          { cid: this.cID, oid: this.oID },
          this.$options.SETTING_NAME,
          form
        );
        if (!setting) {
          throw new Error(
            this.$t("edit_campaign.setting_save_error", "Setting {name} cannot be saved", {
              name: this.$options.SETTING_NAME,
            })
          );
        }

        return setting;
      } catch (e) {
        if (isAxiosError(e)) {
          this.$_notificationsMixin_handleCatch(e);
        } else if (e instanceof Error) {
          this.$_notificationsMixin_makeToast("Error", e.message, "danger");
        }

        console.error(e);
        throw e;
      }
    },
    onChange(value) {
      this.loading = true;
      this.wrapAddSetting({ campaign_mode: value })
        .then((setting) => {
          this.$_notificationsMixin_makeToast(
            "Success",
            this.$t("edit_campaign.setting_saved", "Saved successfully"),
            "success"
          );

          this.form.campaign_mode = setting.attributes.meta_data.campaign_mode;
        })
        .catch(() => {
          this.loading = true;
          this.wrapGetSetting()
            .then((setting) => {
              this.form.campaign_mode = setting.attributes.meta_data.campaign_mode;
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.addSetCampaignModeSetting && this.addSetCampaignModeSettingMeta) {
      this.loading = true;
      this.wrapGetSetting()
        .then((setting) => {
          this.form.campaign_mode = setting.attributes.meta_data.campaign_mode;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
});
</script>

<template>
  <b-form-group
    v-if="addSetCampaignModeSetting && addSetCampaignModeSettingMeta"
    id="set-campaign-mode"
    :label="$t('edit_campaign.add_set_campaign_mode_setting_setting_name', 'Set Campaign Mode')"
    label-for="set-campaign-mode-input"
  >
    <b-form-select
      id="set-campaign-mode-input"
      v-model="form.campaign_mode"
      @change="onChange"
      :options="campaignModeOptions"
      :disabled="loading"
    ></b-form-select>
  </b-form-group>
</template>
