<template>
  <b-overlay class="tw-col-span-3" :show="loading">
    <b-row class="py-4">
      <b-col>
        <h4>{{ $t("donor_dashboard.my_teams", "My teams") }}</h4>
      </b-col>
      <div>
        <DonorExportTeams @proc-request-finished="onExportFinished">
          <template #default="{ exportTeams, regenerating }">
            <b-button
              class="mx-1 bttn bttn--sm bttn--orange text-nowrap"
              @click="exportTeams"
              :disabled="regenerating"
            >
              <b-spinner class="mr-2" v-if="regenerating" small></b-spinner>
              {{ $t("donor_dashboard.export_teams", "Export Teams") }}
            </b-button>
          </template>
        </DonorExportTeams>
      </div>
    </b-row>

    <TotalStats />

    <DonorMyTeamsSortby
      v-if="donorTeams.length !== 0"
      :loading="loading"
      :value="sortBy"
      @change="sortDonorTeams"
    />

    <b-row v-if="donorTeams.length !== 0">
      <b-col class="py-3" cols="12" v-for="o in donorTeams" :key="o.team.id">
        <teams-card-big
          :team="o.team"
          :campaign="o.campaign"
          :team-stats="o.team_stats"
          :customFields="o.team_custom_fields || {}"
          show-donor-page-team-link
          @click-edit-team="openTeamForm(o)"
        />
      </b-col>
    </b-row>

    <b-row class="py-4" v-else>
      <b-col cols="12" class="text-center">
        <p class="text-muted font-italic">{{ $t("donor_dashboard.no_donor_teams_found") }}</p>
      </b-col>
    </b-row>
    <team-form ref="TeamForm" />
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from "vuex";
import teamsCardBig from "./subcomponents/teams-card-big.vue";
import teamForm from "./subcomponents/team-form.vue";
import TotalStats from "./donor-my-teams-stats.vue";
import DonorExportTeams from "./donor-export-teams.vue";
import DonorMyTeamsSortby from "./donor-my-teams-sortby.vue";

export default {
  COMMON_EXTEND: ["stats", "campaign", "organization", "permissions", "team_custom_fields"],

  components: {
    teamsCardBig,
    teamForm,
    TotalStats,
    DonorExportTeams,
    DonorMyTeamsSortby,
  },
  data() {
    return {
      sortBy: "goal",
    };
  },
  mounted() {
    this.getDonorTeams({
      extend: this.$options.COMMON_EXTEND,
      sortBy: this.sortBy,
    });
  },
  computed: {
    ...mapState({
      donorTeams: (state) => state.donorTeams.collection,
      loading: (state) => state.donorTeams.loading.getDonorTeams,
    }),
  },
  watch: {
    donorTeams(val) {
      if (val?.length) {
        const p = {
          cId: val[0]?.campaign?.campaign_id,
        };
        this.fetchIsActive(p);
      }
    },
  },
  methods: {
    ...mapActions({
      getDonorTeams: "donorTeams/getDonorTeams",
      getDonorTeamsStats: "donorTeams/getDonorTeamsStats",
      fetchIsActive: "seferTorahPages/fetchIsActive",
    }),
    openTeamForm(o) {
      const team = o.team;
      const permissions = o.login_to_team.permissions;

      team.campaign = o.campaign;
      team.organization = o.organization;

      this.$refs.TeamForm.openEditModal(team, permissions);
    },
    onExportFinished(attrs) {
      const a = document.createElement("a");
      this.$el.appendChild(a);
      a.download = attrs.title;
      a.href = attrs.meta.file;
      a.click();
      this.$el.removeChild(a);
    },
    sortDonorTeams(sortBy) {
      this.sortBy = sortBy;
      this.getDonorTeams({
        extend: this.$options.COMMON_EXTEND,
        sortBy,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
