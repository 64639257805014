import urls from "../../constants/urls.js";
import axios from "axios";

import router from "@/router";

const state = {
  teams: [],
  loadingTeam: true,
  loadingChildrenTeam: true,
  teamsCount: null,
  totalTeams: 0,
  allTeams: [],
  allTeamsWithStats: [],
  bulkEventPageTeams: [],
};

const generateBulkMutations = (stateField) => {
  return {
    [`check_${stateField}`](state, data) {
      state[stateField].push(data);
    },
    [`uncheck_${stateField}`](state, id) {
      if (id) {
        state[stateField] = state[stateField].filter((v) => id !== v.id);
      } else {
        state[stateField] = [];
      }
    },
  };
};

const mutations = {
  setTeams(state, data) {
    state.teams = data;
  },

  setLoadingTeam(state, value) {
    state.loadingTeam = value;
  },

  loadingChildrenTeam(state, value) {
    state.loadingChildrenTeam = value;
  },

  setTeamsCount(state, value) {
    state.teamsCount = value;
  },

  setTotalTeams(state, value) {
    state.totalTeams = value;
  },

  setAllTeams(state, data) {
    state.allTeams = data;
  },
  setAllTeamsWithStats(state, data) {
    state.allTeamsWithStats = data;
  },
  ...generateBulkMutations("bulkEventPageTeams"),
};

const getters = {
  getAllTeamsList(state) {
    return state.allTeams;
  },
  allTeamsWithStats(state) {
    return state.allTeamsWithStats;
  },
};

const actions = {
  getTeams({ commit }, props) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.apiTeams
          .replace(":orgId", orgId)
          .replace(":campaignId", router.currentRoute.params.id) + props
      )
      .then((response) => {
        let team = response.data.data;
        commit("setTeamsCount", Number(response.headers["x-search-teams"]));
        commit("setTotalTeams", Number(response.headers["x-total-teams"]));
        commit("setTeams", team);
        commit("setLoadingTeam", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getAllTeams({ commit }, id) {
    let orgId = localStorage.getItem("orgId");
    const params = { extend: ["stats"] };
    if (Number(id) === 0) {
      throw new Error("getAllTeams campaign id is 0");
    }
    axios
      .get(urls.apiTeams.replace(":orgId", orgId).replace(":campaignId", id), {
        params,
      })
      .then(({ data, headers }) => {
        commit("setTeamsCount", Number(headers["x-search-teams"]));
        commit("setTotalTeams", Number(headers["x-total-teams"]));

        let team = data.data;
        commit("setAllTeams", team);
        commit("setAllTeamsWithStats", team);
      })
      .catch((e) => {
        console.error(e);
      });
  },

  getTeamsByCampaignId({ commit }, [id, params, search = false]) {
    const orgId = localStorage.getItem("orgId");

    if (params && !search) commit("setLoadingTeam", true);

    if (Number(id) === 0) {
      throw new Error("getTeamsByCampaignId campaign id is 0");
    }

    return axios
      .get(urls.apiTeams.replace(":orgId", orgId).replace(":campaignId", id), {
        params,
      })
      .then(({ data, headers }) => {
        commit("setTeamsCount", Number(headers["x-search-teams"]));
        commit("setTotalTeams", Number(headers["x-total-teams"]));
        if (params && !search) {
          commit("setTeams", data.data);
          commit("setLoadingTeam", false);
        } else {
          commit("setAllTeams", data.data);
        }

        return data.data;
      });
  },
  getTeamsByParentId({ commit }, [id, params]) {
    const orgId = localStorage.getItem("orgId");
    commit("loadingChildrenTeam", true);
    if (Number(id) === 0) {
      throw new Error("getTeamsByParentId campaign id is 0");
    }
    return axios
      .get(urls.apiTeams.replace(":orgId", orgId).replace(":campaignId", id), {
        params,
      })
      .then(({ data, headers }) => {
        commit("setTeamsCount", Number(headers["x-search-teams"]));
        commit("setTotalTeams", Number(headers["x-total-teams"]));
        return data;
      })
      .finally(() => {
        commit("loadingChildrenTeam", false);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
