<script setup>
import { getCookie } from "@/helpers/cookie";
import { useStore } from "@/store/store";
import { computed, onMounted } from "vue";

const store = useStore();

defineProps({
  size: {
    type: String,
    default: "md",
  },
});

const languageList = computed(() => {
  let langArr = store.state.account.languages;

  let resultArr = [];

  for (var i = 0; i < langArr.length; i++) {
    let obj = {
      text: langArr[i].attributes.name,
      value: langArr[i].attributes.code,
    };

    resultArr.push(obj);
  }

  return resultArr;
});

const language = computed({
  get() {
    return store.state.translation.locate;
  },
  set(newValue) {
    store.dispatch("setTranslationPermanent", newValue);
  },
});

onMounted(() => {
  const cookieLang = getCookie("language");
  if (cookieLang && cookieLang !== "undefined" && cookieLang !== "null") {
    language.value = cookieLang;
  } else {
    language.value = "en";
  }
});
</script>

<template>
  <b-form-select id="language-select" v-model="language" :options="languageList" :size="size" />
</template>
