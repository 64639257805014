<template>
  <div>
    <b-modal
      id="media-storage-cropper-modal"
      ref="modal"
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="onHide"
      @ok="ok"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ $t(`dashboard.media_storage_cropper_modal_title`, `Crop media`) }}
        </h2>
      </div>
      <div>
        <b-alert :show="srcImage && aspectRatio && aspectRatio !== 1">
          <span v-html="aspectRatioInfo"></span>
        </b-alert>
        <b-row v-if="allowAnyAspectRatio" class="mb-2">
          <b-col>
            <b-form-checkbox
              v-model="anyAspectRatioToggle"
              id="anyAspectRatioToggle"
              name="anyAspectRatioToggle"
            >
              <label class="form-unit__label" for="anyAspectRatioToggle">
                {{
                  $t(
                    `dashboard.media_storage_cropper_modal_any_aspect_ratio`,
                    `Allow any aspect ratio`
                  )
                }}
              </label>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="srcImage && !this.tag.includes('video')">
          <b-col cols="6">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("dashboard.media_storage_original_img", "Original image:") }}
            </legend>
            <MediaStorageCropper
              ref="cropper"
              :src="srcImage"
              :stencil-props="{ aspectRatio }"
              :tag="tag"
              @change="onChange"
            />
          </b-col>
          <b-col v-show="previewImageSrc" cols="6">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("dashboard.media_storage_final_img", "Final image:") }}
            </legend>
            <MediaStorageCropperPreview :image="previewImageSrc" :tag="tag" />
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label">
              {{
                $t("dashboard.media_storage_final_img_size", "Image size: {x}x{y}", {
                  x: cropperCoordinates.width,
                  y: cropperCoordinates.height,
                })
              }}
            </legend>
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{
                $t(
                  "dashboard.media_storage_final_img_aspect_ratio",
                  "Aspect ratio: {aspectRatioCropperCoordinates}",
                  {
                    aspectRatioCropperCoordinates,
                  }
                )
              }}
            </legend>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="bttn bttn--lg bttn--blue" @click="cancel">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="bttn bttn--lg bttn--orange" @click="ok">Ok</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mediaTagAspectRatio, mediaTags } from "@/constants/media-tags";
import { mapActions, mapState } from "vuex";
import MediaStorageCropper from "./MediaStorageCropper.vue";
import MediaStorageCropperPreview from "./MediaStorageCropperPreview.vue";

function gcd(a, b) {
  return b == 0 ? a : gcd(b, a % b);
}

export default {
  props: {
    campaignTemplate: {
      type: String,
      default: "",
    },
  },
  components: {
    MediaStorageCropper,
    MediaStorageCropperPreview,
  },
  data() {
    return {
      src: "",
      tag: "",
      file: null,
      customAspectRatio: null,
      previewImageSrc: "",
      cropperCanvas: null,
      cropperCoordinates: { width: 0, height: 0 },
      callback: () => null,
      callbackClose: () => null,
      allowAnyAspectRatio: false,
      anyAspectRatioToggle: false,
    };
  },
  computed: {
    ...mapState({
      metas: (state) => state.campaign.metas,
    }),
    publicCampaignPageFeaturesVersion2() {
      const data = this.metas?.public_campaign_page_features_version_2;

      if (data?.value) {
        return data;
      }

      return {
        enable_past_achievements: false,
        enable_storyfest_section: false,
        enable_live_feed_section: false,
      };
    },

    optionsTags() {
      return mediaTags({
        publicCampaignPageFeaturesVersion2: this.publicCampaignPageFeaturesVersion2,
      });
    },
    optionsAspectRatio() {
      return mediaTagAspectRatio({
        publicCampaignPageFeaturesVersion2: this.publicCampaignPageFeaturesVersion2,
      });
    },

    fileUrl() {
      return this.file && URL.createObjectURL(this.file);
    },
    srcImage() {
      return this.fileUrl || this.src;
    },
    aspectRatio() {
      if (this.anyAspectRatioToggle) {
        return null;
      }

      if (this.customAspectRatio) {
        return this.customAspectRatio?.value || 1;
      }

      const mtar = this.optionsAspectRatio[this.tag];
      if (mtar && (mtar.value?.height || mtar.value?.width)) {
        return mtar.value.height / mtar.value.width;
      }

      return (mtar && mtar.value) || 1;
    },

    aspectRatioInfo() {
      const tag = this.optionsTags.find((el) => el.value === this.tag)?.text || "";
      const fallback = `For "{tmpl}" template, "{tag}" image, the recomended aspect ratio is <b>{aspectRatio}</b>, use the croping tool bolow to crop the original image according to the guideline`;

      return this.$t("dashboard.media_storage_aspect_ratio_info", fallback, {
        tmpl: this.campaignTemplate.replace(/\b[a-z]/g, (x) => x.toUpperCase()),
        tag,
        aspectRatio: this.aspectRatioText,
      });
    },

    aspectRatioText() {
      if (this.customAspectRatio) {
        return this.customAspectRatio?.text || "";
      }

      const mtar = this.optionsAspectRatio[this.tag];
      return (mtar && mtar.text) || "";
    },

    aspectRatioCropperCoordinates() {
      if (this.cropperCoordinates) {
        const w = this.cropperCoordinates.width;
        const h = this.cropperCoordinates.height;
        const r = gcd(w, h);
        return `${w / r}:${h / r}`;
      }

      return "";
    },
  },
  methods: {
    ...mapActions({
      fetchCreateFileUrl: "mediaStorage/fetchCreateFileUrl",
    }),
    open({ file, tag, aspectRatio, allowAnyAspectRatio } = {}) {
      this.file = file;
      this.tag = tag;
      this.customAspectRatio = aspectRatio;
      this.allowAnyAspectRatio = allowAnyAspectRatio;
      this.$refs.modal.show();
    },
    close() {
      this.$refs.modal.hide();
    },
    onChange({ canvas, coordinates }) {
      this.previewImageSrc = canvas?.toDataURL() || "";
      this.cropperCanvas = canvas;
      this.cropperCoordinates = coordinates;
    },
    getCroppedImgURL() {
      return new Promise((resolve) => {
        if (this.cropperCanvas) {
          this.cropperCanvas.toBlob((blob) => {
            const file = new File([blob], `${Date.now()}.jpeg`, {
              lastModified: Date.now(),
              type: "image/jpeg",
            });
            resolve(file);
          }, "image/jpeg");
        } else {
          resolve(this.src);
        }
      });
    },
    onOK(cb) {
      this.callback = cb;
    },
    onClose(cb) {
      this.callbackClose = cb;
    },
    ok() {
      this.getCroppedImgURL().then(this.callback);
    },
    onHide() {
      this.$emit("hide");
      this.callbackClose();
      this.fileUrl && URL.revokeObjectURL(this.fileUrl);

      this.file = "";
      this.previewImageSrc = "";
      this.customAspectRatio = null;
      this.callback = () => null;
      this.callbackClose = () => null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
