<template>
  <div>
    <div class="dashboard-wrapper">
      <div>
        <div class="category-title">
          <b-dropdown id="ddown" class="h1-like">
            <template slot="button-content">
              <span>Moshes Videography Studio</span>
            </template>
            <b-dropdown-item></b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="dashboard-statictis-block">
          <div class="dashboard-statictis-data">
            <dashboard-top-stat></dashboard-top-stat>
            <div class="dashboard-blocks-top">
              <div class="updates-stats dashboard-block">
                <b-tabs :no-fade="true" :lazy="true">
                  <b-tab :no-fade="true" :lazy="true" :title="$t('dashboard.recent')">
                    <dashboard-recent></dashboard-recent>
                  </b-tab>
                  <b-tab :no-fade="true" :lazy="true" :title="$t('dashboard.updates')">
                    <dashboard-updates></dashboard-updates>
                  </b-tab>
                </b-tabs>
              </div>

              <dashboard-chart></dashboard-chart>
            </div>
          </div>
        </div>

        <div class="dashboard-blocks-main">
          <dashboardDonations
            :campaignid="selectedCampaignId"
            @load-doination="getCampaignDonation"
            @load-pending-donations="getPendingCampaignDonation"
          ></dashboardDonations>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardTopStat from "./subcomponents/dashboardTopStat.vue";
import dashboardRecent from "./subcomponents/dashboardRecent.vue";
import dashboardChart from "./subcomponents/dashboardChart.vue";
import dashboardDonations from "./subcomponents/dashboardDonations.vue";
import dashboardUpdates from "./subcomponents/dashboardUpdates.vue";
import addCampaign from "@/views/campaigns/campaign-wizards/v2/campaignAdd.vue";
import { BDropdown } from "bootstrap-vue";
import debounce from "lodash/debounce";
import { mapState } from "vuex";

export default {
  data: function () {
    return {
      selectedCampaignId: 0,
      selectedCampaignTitle: "",
      setIntervalData: null,
    };
  },

  components: {
    dashboardTopStat,
    dashboardRecent,
    dashboardChart,
    dashboardDonations,
    addCampaign,
    dashboardUpdates,
    "b-dropdown": BDropdown,
  },

  computed: mapState({}),

  watch: {},

  directives: {
    debounce,
  },

  methods: {},

  destroyed: function () {
    clearInterval(this.setIntervalData);
  },
};
</script>

<style lang="scss">
.loadings {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  img {
    width: 200px;
  }
}

.dashboard-statictis-block {
  position: relative;
  min-height: 400px;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}

.dashboard-wrapper {
  .category-title {
    margin-bottom: 48px;
  }
  > div > select {
    max-width: 260px;
    width: 100%;
    margin-bottom: 20px;
  }
}

#ddown {
  .dropdown-toggle {
    color: #333333;
    font-size: 32px;
    background-color: transparent;
    border: none;
    &:focus,
    &:active,
    &:hover {
      color: #333333;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    &:after {
      color: #107598;
      border-top: 8px solid;
      border-right: 8px solid transparent;
      border-bottom: 0;
      border-left: 8px solid transparent;
    }
    > span {
      font-size: 18px;
      color: #107598;
    }
  }
  .dropdown-toggle[area-expanded="true"] {
    color: #333333;
    background-color: transparent;
  }
  .dropdown-item {
    &:focus,
    &:active,
    &:hover {
      outline: 0;
    }
  }
}
.topstat-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .count-block {
    border-radius: 4px;
  }
  > .count-block {
    width: 100%;
    white-space: nowrap;
    @media only screen and (min-width: 468px) {
      max-width: 280px;
    }
    @media only screen and (min-width: 768px) {
      justify-content: flex-start;
    }
    @media only screen and (min-width: 768px) {
      width: 39%;
      max-width: initial;
      padding: 10px 10px;
    }
    @media only screen and (min-width: 992px) {
      width: 33%;
    }
  }
  &__section {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (min-width: 468px) {
      flex-wrap: nowrap;
    }
    @media only screen and (min-width: 768px) {
      margin-left: auto;
      width: 60%;
      justify-content: space-between;
    }
    @media only screen and (min-width: 992px) {
      width: 66%;
    }
    .count-block {
      width: 100%;
      @media only screen and (min-width: 468px) {
        max-width: 160px;
      }
      @media only screen and (min-width: 768px) {
        &__title,
        &__moment,
        &__total {
          text-align: initial;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 49.2%;
        max-width: initial;
        padding: 10px 40px;
      }
      &__data {
        display: flex;
        flex-direction: column;
        @media only screen and (min-width: 768px) {
          flex-grow: 1;
        }
      }
    }
    .count-block:not(:last-of-type) {
      margin-right: 0;
      @media only screen and (min-width: 468px) {
        margin-right: 15px;
      }
      @media only screen and (min-width: 768px) {
        margin-right: 5px;
      }
    }
  }
}

.dashboard-blocks-top {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.chart {
  width: 100%;
  margin-bottom: 20px;
  min-height: 400px;
  padding: 30px;
  position: relative;
  @media only screen and (min-width: 992px) {
    width: 66%;
    margin-bottom: initial;
  }
  .dashboard-block__title,
  .dashboard-block__subtitle {
    padding-right: 50px;
  }
  .line-chart {
    position: relative;
    min-height: 260px;
  }

  > .bttn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 30px;
    right: 30px;
    > svg {
      width: 22px;
      height: 22px;
      display: inline-block;
      fill: #ffffff;
    }
  }
}

.dashboard-donations {
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;

    .btn-wrapper {
      display: inline-flex;
      margin-left: auto;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
      > div:nth-of-type(1),
      > div:nth-of-type(3),
      > div:nth-of-type(4) {
        margin-bottom: 5px;
      }
      @media only screen and (min-width: 1024px) {
        flex-direction: row;
        align-items: baseline;
      }
    }
  }
  .dashboard-block__title {
    margin: 0;
  }
  &__moderation {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    .bttn:not(:last-of-type) {
      margin-right: 10px;
    }
    &.moderation {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      @media only screen and (min-width: 1360px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
  &__filters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media only screen and (min-width: 660px) {
      flex-direction: row;
    }
    @media only screen and (min-width: 1360px) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
    }

    .filter-block {
      width: 25%;
      min-height: 48px;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      padding: 0px 10px;
      @media only screen and (max-width: 1440px) {
        width: 33%;
      }
      @media only screen and (max-width: 1024px) {
        width: 50%;
      }
      @media only screen and (max-width: 425px) {
        width: 100%;
        padding: 0;
      }
    }
  }

  &__th {
    // display: none;
    // @media only screen and (min-width: 1360px) {
    //   display: flex;
    // }
    .th-item {
      display: flex;
      align-items: center;
      width: 10%;
      min-width: 10%;
      padding: 0 5px;
      font-size: 14px;
      &--15 {
        width: 15%;
        min-width: 15%;
      }
      &--20 {
        width: 20%;
        min-width: 20%;
      }
      &--40 {
        width: 40%;
        min-width: 40%;
      }
    }
  }
}

#importManual {
  .modal-lg {
    width: 1100px;
    max-width: 1100px;
  }
  .import-manual {
    margin-right: -14px;
    margin-left: -14px;

    &__row {
      padding-right: 15px;
      padding-left: 15px;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 20px;
      position: relative;

      &:not(:last-of-type) {
        margin-bottom: 20px;
        border-bottom: 1px solid #e9e9e9;
      }

      &:hover {
        .import-manual__delete {
          opacity: 1;
        }
      }
    }

    &__subrow {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .form-group {
        width: 20%;
        padding-right: 3px;
        margin-bottom: 10px;
      }
    }

    &__mandatory {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-end;
      position: relative;
      .form-group {
        width: 20%;
        padding-right: 3px;
        &--select {
          width: 230px;
          max-width: 230px;
          //margin-right: 20px;
        }
        &--charged {
          width: 15%;
        }
        &.import-manual__checkbox {
          width: 16%;
          margin-bottom: 0;
        }
      }

      .form-group:not(.import-manual__checkbox) {
        margin-bottom: 0;
      }
    }
    &__loader {
      width: 9%;
      align-self: flex-end;
      img {
        height: 60px;
        width: 60px;
      }
    }
    &__action {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &__add-row {
      cursor: pointer;
      margin: 0;
      padding: 0 13px;
      height: 26px;
      font-size: 12px;
      line-height: 26px;
      text-transform: uppercase;
      background-color: #f2f4f5;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 13px;
      &:hover,
      &:focus,
      &:active {
        background-color: #f2f4f5;
        outline: 0;
        box-shadow: none;
      }
    }
    &__label {
      display: inline-block;
      visibility: visible;
      color: #20434e;
      font-size: 13px;
      text-transform: uppercase;
      &--required {
        position: relative;
        &:after {
          content: "*";
          color: red;
          display: inline;
          margin-left: 2px;
          font-size: 13px;
          position: relative;
          top: -2px;
        }
      }
    }
    &__input {
      padding: 5px 10px;
      &:focus,
      &:active {
        border-width: 1px;
        outline: 0;
        box-shadow: none;
      }
    }
    &__select {
      width: 100% !important;
      min-height: 36px;
      padding: 5px 10px;
      height: initial;
      width: initial;
      &:focus,
      &:active {
        border-width: 1px;
        outline: 0;
        box-shadow: none;
      }
    }
    &__checkbox {
      position: relative;
      align-self: flex-end;
      margin-bottom: 10px;
      input {
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
      label {
        margin: 0;
        cursor: pointer;
        padding-left: 24px;
        opacity: initial;
        visibility: initial;
        pointer-events: initial;
        font-size: 14px;
        color: #20434e;
        line-height: 1;
        position: relative;
        top: initial;
        left: initial;
        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #20434e;
          border-radius: 4px;
          transition: border-width 0.2s ease-in-out;
          transform: translateY(-50%) translateZ(0px);
          position: absolute;
          left: 0;
          top: 50%;
        }
      }
      input:checked + label:before {
        border-color: #cc6d4c;
        border-width: 6px;
      }
    }
    &__delete {
      opacity: 0;
      display: inline-block;
      font-size: 14px;
      //font-family: "ProximaNova-Regular", sans-serif;
      color: #cc6d4c;
      text-transform: capitalize;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 10;
      &:hover,
      &:focus {
        color: darken(#cc6d4c, 5%);
        text-decoration: none;
      }
    }
  }
}

.b-dropdown {
  .dropdown-item {
    &:hover,
    &:focus {
      outline: 0;
    }
  }
}

.pagination {
  li {
    width: 30px;
    height: 30px;
    display: flex;
    @media only screen and (min-width: 768px) {
      width: 50px;
      height: 50px;
    }
    .page-link {
      width: 100%;
      height: auto;
      display: flex;
      //line-height: 30px;
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }
  .page-item.disabled {
    .page-link {
      height: auto;
      display: flex;
      //line-height: 30px;
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }
}

.empty {
  flex-direction: column;
}

.add-campaign {
  padding-top: 50px;
}
.no-chart-data-message {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  justify-content: center;
  text-align: center;
  font-style: italic;
  color: #939da5;
}

.updates-stats.updates-stats {
  width: 100%;
  order: 2;
  padding: 0;
  @media only screen and (min-width: 992px) {
    order: initial;
    width: 33%;
  }
}

.tab-content {
  padding: 15px;
}

.updates-stats.dashboard-block .nav-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background: rgba(16, 117, 152, 0.06);
  a {
    font-size: 12px;
    color: #107598;
    display: block;
  }
  .nav-item {
    width: 50%;
  }
}

.topstat-block > .count-block .count-block__data {
  max-width: 60%;
}
</style>
