import { render, staticRenderFns } from "./dashboardTicketsUnit.vue?vue&type=template&id=79f68065&scoped=true&"
import script from "./dashboardTicketsUnit.vue?vue&type=script&lang=js&"
export * from "./dashboardTicketsUnit.vue?vue&type=script&lang=js&"
import style0 from "./dashboardTicketsUnit.vue?vue&type=style&index=0&id=79f68065&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f68065",
  null
  
)

export default component.exports