<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">Profile Description</h2>
      <div>
        <p class="selected-tag-text">{{ $t("edit_campaign.select_description_tag") }}</p>
        <b-dropdown
          :disabled="!hasAccess"
          variant="-sm bttn--blue"
          right
          :text="
            descriptionTag == ''
              ? $t('edit_campaign.untagged')
              : $t('edit_campaign.' + descriptionTag + '')
          "
        >
          <b-dropdown-item @click="changeDescriptionTag('countdown')">{{
            $t("edit_campaign.countdown")
          }}</b-dropdown-item>
          <b-dropdown-item @click="changeDescriptionTag('primary_goal')">{{
            $t("edit_campaign.primary_goal")
          }}</b-dropdown-item>
          <b-dropdown-item @click="changeDescriptionTag('bonus_goal')">{{
            $t("edit_campaign.bonus_goal")
          }}</b-dropdown-item>
          <b-dropdown-item @click="changeDescriptionTag('completed')">{{
            $t("edit_campaign.completed")
          }}</b-dropdown-item>
          <b-dropdown-item @click="changeDescriptionTag('')">{{
            $t("edit_campaign.untagged")
          }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <form method="POST" class="campaign-description">
      <error :errors="errors"></error>

      <ul class="language-selected">
        <li
          v-for="lang in languagesAdded"
          :key="lang.id"
          @click="isSelected(lang.attributes.language)"
          class="language-selected__unit"
          :class="{ active: languageSelected === lang.attributes.language }"
          v-text="setFullName(lang.attributes.language)"
        ></li>

        <b-dropdown
          :disabled="!hasAccess"
          id="ddown1"
          text="+ Add language"
          class="dropdown"
          variant="-sm bttn--bordered-blue"
        >
          <b-dropdown-item
            :disabled="!hasAccess"
            v-for="(lang, index) in languageSet"
            :key="index"
            >{{ lang.long }}</b-dropdown-item
          >
        </b-dropdown>
      </ul>

      <!-- TEXT EDITOR AREA -->
      <div class="description-area">
        <div class="editor-wrap">
          <div class="row">
            <div class="col-auto mr-auto">
              <button
                type="button"
                class="bttn bttn--lg bttn--orange bttn--inverted-orange"
                name="button"
              >
                {{ $t("edit_campaign.delete") }}
              </button>
            </div>
            <div class="col-auto">
              <button
                type="submit"
                :disabled="!hasAccess"
                class="bttn bttn--lg bttn--orange bttn--mw255"
                value="Save"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import error from "@/components/errors.vue";

export default {
  data() {
    return {
      languageSelected: "",
      languagesAdded: null,
      languageSet: null,
      editorOption: {
        modules: {
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      },
      load: false,
      errors: [],
      descriptionTag: "",
      changeTag: false,
      seteTimeoutFunc: null,
    };
  },

  components: {
    error: error,
  },

  computed: mapState({}),

  methods: {},

  watch: {},

  created: function () {},
};
</script>

<style lang="scss">
.quill-editor {
  margin-bottom: 30px;
}
.quill-editor .ql-container {
  height: 400px;
  position: relative;
}

.language-selected .btn.btn-secondary.dropdown-toggle {
  background: transparent;
  border: 1px solid rgba(32, 67, 78, 0.15);
  color: #20434e;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  height: 42px;
  border-radius: 0;
}

.language-selected .dropdown-menu {
  color: #20434e;
}

.delete-description {
  background: transparent;
  border: 0;
  font-size: 16px;
  color: #cc6d4c;
  display: block;
  margin: 20px 0;
}

.loadin-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
  }
}

.alert-danger-editor li {
  display: block;
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #f5c6cb;
  font-size: 14px;
}

.selected-tag-text {
  color: #107598;
  margin-bottom: 10px;
}
</style>
