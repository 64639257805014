<template>
  <div class="donation-statistic tw-col-span-3" v-if="statistic.attributes">
    <h4>
      {{ $t("donor_dashboard.my_giving", "My giving") }}
    </h4>
    <div v-if="currencies.length > 1" class="my-2">
      <b-dropdown :text="selectedCurrency.toUpperCase()" class="custom-dropdown" ref="dropdownMenu">
        <b-dropdown-item v-for="curr in currencies" :key="curr" @click="selectedCurrency = curr">
          {{ curr.toUpperCase() }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="total">
      <span>
        {{ getFormatedAmountString(sumRealAmt, selectedCurrency) }}
      </span>
    </div>
    <div class="res">
      <span>{{ $t("donor_dashboard.effective", "Effective") }}</span>
      <span class="green">
        {{ getFormatedAmountString(sumTotalAmt, selectedCurrency) }}
      </span>
      <button
        type="button"
        class="btn tooltip-btn"
        data-toggle="tooltip"
        data-placement="top"
        :title="$t('donor_dashboard.effective_tooltip')"
      >
        ?
      </button>
    </div>
    <div class="periods">
      <ul class="list-inline">
        <li
          v-for="statisticDate in statisticDates"
          :key="statisticDate.default"
          class="list-inline-item"
          :class="{ active: activeLi === statisticDate.default }"
          @click="getStatisticDate(statisticDate.default)"
        >
          {{ $t("donor_dashboard." + statisticDate.key, statisticDate.default) }}
        </li>
      </ul>
    </div>
    <div class="row justify-content-between align-items-stretch">
      <div class="col">
        <div class="counter-wrap">
          <div class="counter blue">{{ statistic.attributes.donations_count }}</div>
          <div class="counter-title">
            {{ $t("donor_dashboard.donation_made", "Donation made") }}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="counter-wrap">
          <div class="counter red">{{ statistic.attributes.recurring_count }}</div>
          <div class="counter-title">
            {{ $t("donor_dashboard.donation_recurring", "Donation recurring") }}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="counter-wrap">
          <div class="counter green">{{ statistic.attributes.organizations_count }}</div>
          <div class="counter-title">
            {{ $t("donor_dashboard.organizations_supported", "Organizations supported") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getFormatedAmountString } from "@/constants/currencies";

export default {
  data() {
    return {
      activeLi: "All",
      statisticDates: [
        {
          key: "statistic_days",
          default: "1D",
        },
        {
          key: "statistic_weeks",
          default: "1W",
        },
        {
          key: "statistic_months",
          default: "3M",
        },
        {
          key: "statistic_years",
          default: "1Y",
        },
        {
          key: "statistic_all",
          default: "All",
        },
      ],
      selectedCurrency: "usd",
    };
  },
  computed: {
    ...mapState({
      statistic: (state) => {
        return state.donorStatistic.statistic;
      },
    }),

    sumRealAmt() {
      const data = this.statistic.attributes.sum_real_amt?.[this.selectedCurrency] || 0;
      return data;
    },

    sumTotalAmt() {
      const data = this.statistic.attributes.sum_total_amt?.[this.selectedCurrency] || 0;
      return data;
    },

    currencies() {
      return Object.keys(this.statistic?.attributes?.sum_real_amt || {});
    },
  },
  watch: {
    currencies: {
      handler(list) {
        if (list?.includes("usd")) {
          this.selectedCurrency = "usd";
        } else {
          this.selectedCurrency = list?.[0] || "";
        }
      },
      immediate: true,
    },
  },
  created: function () {
    this.$store.dispatch("getDonorStatisticInfo");
  },
  destroyed() {
    console.log("destroyed");
  },
  methods: {
    getFormatedAmountString,

    getStatisticDate: function (period) {
      this.activeLi = period;
      var date = new Date();
      var date_from;

      switch (period) {
        case "1D":
          date_from = (date.setDate(date.getDate() - 1) / 1000) | 0;
          break;
        case "1W":
          date_from = (date.setDate(date.getDate() - 7) / 1000) | 0;
          break;
        case "1M":
          date_from = (date.setMonth(date.getMonth() - 1) / 1000) | 0;
          break;
        case "3M":
          date_from = (date.setMonth(date.getMonth() - 3) / 1000) | 0;
          break;
        case "1Y":
          date_from = (date.setFullYear(date.getFullYear() - 1) / 1000) | 0;
          break;

        default:
          date_from = (new Date(1970, 1, 1).getTime() / 1000) | 0;
          break;
      }

      let params = {
        date_to: (Date.now() / 1000) | 0,
        date_from: date_from,
      };

      this.$store.dispatch("getDonorStatisticById", params);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.donation-statistic {
  padding: 55px 3%;
  color: #a0a1ac;
  text-align: center;
  letter-spacing: -1.6px;
  h4 {
    color: $bb-100;
    margin-bottom: 0;
  }
  .total {
    font-size: 70px;
    color: $fd;
    line-height: 1;
    margin-bottom: 22px;
    & > * {
      display: inline-block;
    }
    span {
      vertical-align: middle;
      line-height: 1;
    }
    sup {
      font-size: 40px;
      top: -0.3em;
      margin-right: 13px;
    }
    sub {
      font-size: 36px;
      line-height: 1;
      bottom: -0.2em;
    }
  }
  .green {
    color: #00d39c;
  }
  .res {
    margin-bottom: 60px;
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-size: 24px;
    }
    .green {
      font-size: 26px;
      margin-right: 13px;
      margin-left: 7px;
    }
  }
  .tooltip-btn {
    display: inline-block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    content: "?";
    font-weight: bold;
    background: #1075982b;
    padding: 0;
    border-radius: 50%;
    line-height: 1.2;
    color: $bb-100;
  }
  .periods {
    color: #00d39c;
    font-size: 20px;
    margin-bottom: 32px;
    li {
      padding: 5px 13px;
      border-radius: 17px;
      line-height: 1.2;
      width: 57px;
      cursor: pointer;
      &.active {
        background: #00d39c;
        color: #fff;
        font-family: "VitoExtended-Bold";
      }
    }
  }
  .counter-wrap {
    opacity: 0.8;
    border-radius: 4px;
    background-color: #f7fbff;
    color: #a0a1ac;
    text-align: center;
    border: 1px solid #e6eaed;
    height: 100%;
    padding: 17px 15px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    .counter {
      font-size: 55px;
      &.blue {
        color: $bb-100;
      }
      &.red {
        color: #ff5454;
      }
      &.green {
        color: #00d39c;
      }
    }
    .counter-title {
      font-size: 24px;
    }
  }
}
</style>
