<script>
import { defineComponent } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    team: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Object,
      required: true,
    },
  },
  computed: {
    popoverId() {
      return `team-${this.team.id}-custom-fields-popover`;
    },
  },
});
</script>

<template>
  <div :class="$attrs.class" v-if="Object.keys(customFields).length > 0">
    <b-button
      :id="popoverId"
      variant="link"
      class="!tw-p-0 !tw-h-auto !tw-leading-none !tw-text-[15px]"
    >
      {{ $t("donor_dashboard.team_custom_fields_btn_label", "More info") }}
    </b-button>
    <b-popover :target="popoverId" triggers="hover focus">
      <template #title>
        {{ $t("donor_dashboard.team_custom_fields_title", "Additional team data") }}
      </template>
      <dl class="row tw-mb-0">
        <template v-for="([field, value], i) in Object.entries(customFields)">
          <dt class="col-sm-4" :key="`custom-field-${field}-${i}`">
            {{ field }}
          </dt>
          <dd class="col-sm-8" :key="`custom-value-${field}-${i}`">
            {{ value }}
          </dd>
        </template>
      </dl>
    </b-popover>
  </div>
</template>
