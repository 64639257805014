import { dashboardApiHost, publicApiHost } from "@/constants/urls.js";
import axios from "axios";
import { ref } from "vue";

async function getProtectedMyTeams(params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams`;
  const { data } = await axios.get(url, {
    params,
  });

  return {
    data,
  };
}

async function getProtectedMyTeamsStats(params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/donor_account/logins_to_teams/teams_stats`;
  const { data } = await axios.get(url, {
    params,
  });

  return {
    data,
  };
}

async function getPublicTeamsByParentId(campaignId, parentTeamId, params) {
  const url = `${publicApiHost}/api/v1/campaign/${campaignId}/teams`;
  const { data, headers } = await axios.get(url, {
    params: {
      ...params,
      parent_team_id: parentTeamId,
    },
  });

  return {
    data: data.data,
    totalTeams: headers["x-total-teams"],
    searchTeams: headers["x-search-teams"],
  };
}

export function usePublicMyTeamsList() {
  const teams = ref([]);
  const loading = ref(false);
  const total = ref(0);

  async function fetchPublicMyTeams(campaignId, parentTeamId, params) {
    loading.value = true;

    try {
      const { data, totalTeams } = await getPublicTeamsByParentId(campaignId, parentTeamId, params);
      teams.value = data;
      total.value = Number(totalTeams) || 0;

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    teams,
    total,
    loading,
    fetchPublicMyTeams,
  };
}

export function useProctectedMyTeamsList() {
  const teams = ref([]);
  const loading = ref(false);
  const teamStats = ref({});

  async function fetchProtectedMyTeams(params) {
    loading.value = true;

    try {
      const { data } = await getProtectedMyTeams(params);
      teams.value = data;

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchProtectedMyTeamsStats(params) {
    loading.value = true;

    try {
      const { data } = await getProtectedMyTeamsStats(params);
      teamStats.value = data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    teams,
    teamStats,
    loading,
    fetchProtectedMyTeams,
    fetchProtectedMyTeamsStats,
  };
}
