<template>
  <div v-if="CreateOfflineCampaignDonation && !CanSeeCampaignDashboard">
    <button
      class="tw-w-full tw-h-[56px] tw-flex tw-text-start tw-p-[15px] tw-ps-[60px] custom-xl:tw-ps-[40px] tw-rounded-r-[50px] tw-bg-[#50cdaa] tw-border-[#3CC7A0] tw-text-[#313131] tw-outline-none tw-text-[16px] tw-shadow-lg tw-border hover:tw-font-vito-bold hover:tw-no-underline"
      @click="open"
    >
      {{ $t("dashboard.add_button_title", "Add offline donation") }}
    </button>

    <DonationAddModal ref="DonationAddModal" hideAddBtn inlineSelectCampaign />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DonationAddModal from "@/views/dashboard/subcomponents/donationAddModal.vue";

export default {
  components: { DonationAddModal },

  data() {
    return {
      cmp: null,
    };
  },

  computed: {
    ...mapState({
      CreateOfflineCampaignDonation: (state) => state.account.access.CreateOfflineCampaignDonation,
      CanSeeCampaignDashboard: (state) => state.account.access.CanSeeCampaignDashboard,
    }),
  },

  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.DonationAddModal.openModal();
      });
    },
  },
};
</script>
