<template>
  <div class="donor-component">
    <div class="xl:tw-container tw-w-full xl:tw-mx-auto donor-wrapper">
      <main-header>
        <template #short-profile="{ menuClose }">
          <short-profile
            hide-shadows
            @click="menuClose"
            class="tw-block lg:tw-hidden"
          ></short-profile>
        </template>
      </main-header>
      <div class="xl:tw-container xl:tw-mx-auto tw-px-4 tw-mb-[70px]">
        <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-5 xl:tw-gap-20">
          <template v-if="!loading && haveAccount">
            <short-profile class="tw-hidden lg:tw-block"></short-profile>
            <router-view class="right-panel" />
          </template>
          <template v-else>
            <b-skeleton
              class="tw-hidden lg:tw-block tw-col-span-1"
              width="100%"
              height="1092px"
            ></b-skeleton>
            <b-skeleton class="tw-col-span-3" width="100%" height="1092px"></b-skeleton>
          </template>
        </div>
      </div>
      <show-settings-confirm-msg />
    </div>
    <CharidyV2Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import mainHeader from "../common-components/main-header.vue";
import CharidyV2Footer from "@/common-components/cdn-v2/cdn-footer.vue";
import shortProfile from "./short-profile.vue";
import showSettingsConfirmMsg from "../modals/show-settings-confirm-msg.vue";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
    };
  },

  components: {
    "main-header": mainHeader,
    CharidyV2Footer,
    "short-profile": shortProfile,
    showSettingsConfirmMsg,
  },

  computed: mapState({
    haveAccount: (state) => {
      return state.donorAccount.account.attributes;
    },
  }),

  methods: {
    async initData() {
      this.loading = true;

      try {
        await this.$store.dispatch("getDonorAccountInfo");
      } catch (e) {
        console.error(e);
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loading = false;
      }
    },
  },

  created() {
    this.initData();
  },
};
</script>

<style lang="scss">
.donor-component {
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/main-bg.png")
    top center no-repeat;
  background-size: 100% auto;
}
.donor-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}
.container-fluid {
  max-width: 1500px;
  margin: 0 auto;
}
.panel {
  box-shadow: 0 8px 32px 0 rgba(35, 40, 71, 0.08);
  background-color: #ffffff;
}
.main {
  margin: 0 auto;
  margin-bottom: 70px;
  max-width: 1440px;
}
</style>
