export const mediaTagAspectRatio = ({
  publicCampaignPageFeaturesVersion2 = {
    enable_past_achievements: false,
  },
} = {}) => {
  return {
    countdown_hero: { text: "64/15", value: 64 / 15 },
    countdown_hero_mobile: { text: "16/9", value: 16 / 9 },
    slider: { text: "16/9", value: 16 / 9 },
    charidy_homepage: { text: "16/9", value: 16 / 9 },
    team_default: { text: "16/9", value: 16 / 9 },
    team_default_avatar: { text: "1/1", value: 1 / 1 },
    campaign_hero: { text: "64/15", value: 64 / 15 },
    campaign_hero_mobile: { text: "16/9", value: 16 / 9 },
    shared_image: { text: "1.91/1", value: 1.91 / 1 },
    projector_banner: {
      text: "5/2",
      value: 5 / 2,
    },
    missionsiyum_left: { text: "16/9", value: 16 / 9 },
    missionsiyum_left_mobile: { text: "16/9", value: 16 / 9 },
    missionsiyum_right: { text: "16/9", value: 16 / 9 },
    missionsiyum_right_mobile: { text: "16/9", value: 16 / 9 },
    brand_slider: { text: "1/1", value: 1 / 1 },
    ...(publicCampaignPageFeaturesVersion2.enable_past_achievements
      ? {
          past_achievements: { text: "16/9", value: 16 / 9 },
        }
      : {}),
    ...(publicCampaignPageFeaturesVersion2.enable_storyfest_section
      ? {
          storyfest_section: { text: "16/9", value: 16 / 9 },
        }
      : {}),
    ...(publicCampaignPageFeaturesVersion2.enable_live_feed_section
      ? {
          live_feed_section: { text: "16/9", value: 16 / 9 },
        }
      : {}),
    footer_image: { text: "9/2", value: 9 / 2 },
    footer_image_mobile: { text: "13/8", value: 13 / 8 },
  };
};

export const mediaTags = ({
  publicCampaignPageFeaturesVersion2 = {
    enable_past_achievements: false,
    enable_storyfest_section: false,
    enable_live_feed_section: false,
  },
} = {}) => {
  return [
    {
      text: "Countdown page image",
      value: "countdown_hero",
    },
    {
      text: "Countdown page video",
      value: "countdown_video",
    },
    {
      text: "Camapign image in the live list",
      value: "charidy_homepage",
    },
    {
      text: "Countdown page mobile image",
      value: "countdown_hero_mobile",
    },
    {
      text: "Regular campaign slider",
      value: "slider",
    },
    {
      text: "Regular campaign video",
      value: "video",
    },
    {
      text: "Unidy/Select campaign slider",
      value: "campaign_hero",
    },
    {
      text: "Campaign page slider mobile",
      value: "campaign_hero_mobile",
    },
    {
      text: "Campaign page share image (fb, whatsapp)",
      value: "shared_image",
    },
    {
      text: "Projector Mode Banner",
      value: "projector_banner",
    },
    {
      text: "Team page slider",
      value: "team_default",
    },
    {
      text: "Team page default avatar",
      value: "team_default_avatar",
    },
    {
      text: "Missionsiyum Left",
      value: "missionsiyum_left",
    },
    {
      text: "Missionsiyum left mobile",
      value: "missionsiyum_left_mobile",
    },
    {
      text: "Missionsiyum right",
      value: "missionsiyum_right",
    },
    {
      text: "Missionsiyum right mobile",
      value: "missionsiyum_right_mobile",
    },
    {
      text: "Brand slider",
      value: "brand_slider",
    },
    ...(publicCampaignPageFeaturesVersion2.enable_past_achievements
      ? [
          {
            text: "Past Achievements",
            value: "past_achievements",
          },
        ]
      : []),
    ...(publicCampaignPageFeaturesVersion2.enable_storyfest_section
      ? [
          {
            text: "Storyfest section",
            value: "storyfest_section",
          },
        ]
      : []),
    ...(publicCampaignPageFeaturesVersion2.enable_live_feed_section
      ? [
          {
            text: "Live feed section",
            value: "live_feed_section",
          },
        ]
      : []),
    {
      text: "Use image as campaign footer",
      value: "footer_image",
    },
    {
      text: "Use image as campaign footer (mobile)",
      value: "footer_image_mobile",
    },
  ];
};
