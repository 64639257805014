<template>
  <b-table
    id="donors-table"
    name="donors-table"
    v-bind="$attrs"
    :busy="loading"
    :items="donors"
    :fields="fields"
    show-empty
    :empty-text="$t('donor_dashboard.no_donors_found', 'No donors found')"
  >
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex flex-row align-items-center justify-content-around">
        <button class="mx-1 bttn bttn-round edit" @click="$emit('send-email', { donor: item })">
          <b-icon-envelope-fill />
        </button>
        <b-form-checkbox
          class="mx-1"
          size="lg"
          :checked="checkedDonors"
          @input="(v) => $emit('update:checkedDonors', v)"
          :unchecked-value="prepareCheckboxObject(null)"
          :value="prepareCheckboxObject(item)"
        >
        </b-form-checkbox>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    donors: {
      type: Array,
      required: true,
    },
    checkedDonors: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      required: true,
    },
    loading: Boolean,
  },
  methods: {
    prepareCheckboxObject(data) {
      return {
        donor: data,
        toString() {
          return JSON.stringify({ donor: data?.id });
        },
      };
    },
  },
};
</script>
