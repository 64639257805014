<template>
  <div class="dashboard-block chart">
    <h2 class="dashboard-block__title">{{ $t("dashboard.chart_title") }}</h2>

    <div class="dashboard-block__subtitle">
      {{ $t("dashboard.chart_description") }}
    </div>

    <button class="bttn bttn--orange" type="button" name="button" @click="reinitChart">
      <b-icon-arrow-counterclockwise v-if="chart" />
      <b-spinner small v-else />
      <span aria-hidden="true" class="sr-only">
        {{ $t("dashboard.refresh_chart", "Refresh chart data") }}
      </span>
    </button>

    <div class="line-chart">
      <canvas id="lineChart"></canvas>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
} from "chart.js";

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

export default {
  data() {
    return {
      chart: null,
    };
  },

  computed: {
    ...mapState({
      chartData: (state) => {
        return state.dashboard.campaignDashboard?.data?.attributes?.chart || {};
      },

      labelList() {
        return this.chartData?.label_list || [];
      },

      donationNArray: function () {
        let array = [];

        if (!this.chartData.data_set) return array;

        this.chartData.data_set.forEach((el) => {
          array.push(el.donation_n);
        });

        return array;
      },

      donationTotalAmtArray: function () {
        let array = [];

        if (!this.chartData.data_set) return array;

        this.chartData.data_set.forEach((el) => {
          array.push(el.donation_amt_total);
        });
        return array;
      },

      uniquePageVisits: function () {
        let array = [];

        if (!this.chartData.data_set) return array;

        this.chartData.data_set.forEach((el) => {
          array.push(el.unique_page_visit_n);
        });

        return array;
      },
    }),
  },

  watch: {
    labelList: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && this.chart) {
          this.reinitChart();
        }
      },
    },
  },

  methods: {
    initialChart() {
      Chart.defaults.font.family = "VitoExtended-Light";
      this.chart = new Chart(document.getElementById("lineChart"), {
        type: "line",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            labels: {
              boxWidth: 12,
              usePointStyle: false,
            },
          },
          scales: {
            count: {
              type: "linear",
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
            amount: {
              type: "linear",
              position: "right",
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
            x: {
              gridLines: {
                display: false,
              },
            },
          },
        },
        data: {
          labels: this.labelList,
          datasets: [
            // remove until GA4 integration fixed https://app.clickup.com/t/85ztemtnt
            // {
            //   label: this.$t("dashboard.page_view_label_chart"),
            //   fill: false,
            //   lineTension: 0.2,
            //   backgroundColor: "#3CC7A0",
            //   borderColor: "#3CC7A0",
            //   borderCapStyle: "butt",
            //   borderDash: [],
            //   borderDashOffset: 0.0,
            //   borderJoinStyle: "miter",
            //   borderWidth: 2,
            //   pointBorderColor: "#3CC7A0",
            //   pointBackgroundColor: "#fff",
            //   pointBorderWidth: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBackgroundColor: "#3CC7A0",
            //   pointHoverBorderColor: "rgba(220,220,220,1)",
            //   pointHoverBorderWidth: 2,
            //   pointRadius: 1,
            //   pointHitRadius: 0,
            //   data: this.uniquePageVisits,
            //   spanGaps: false,
            //   yAxisID: "count",
            // },
            {
              label: this.$t("dashboard.donation_count_label_chart"),
              fill: false,
              lineTension: 0.2,
              backgroundColor: "#F26E66",
              borderColor: "#F26E66",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              borderWidth: 2,
              pointBorderColor: "#F26E66",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#F26E66",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.donationNArray,
              spanGaps: false,
              yAxisID: "count",
            },
            {
              label: this.$t("dashboard.donation_total_label_chart"),
              fill: false,
              lineTension: 0.2,
              backgroundColor: "#107598",
              borderColor: "#107598",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              borderWidth: 2,
              pointBorderColor: "#107598",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#107598",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.donationTotalAmtArray,
              spanGaps: false,
              yAxisID: "amount",
            },
          ],
        },
      });
    },

    reinitChart() {
      this.chart.destroy();
      this.chart = null;
      this.initialChart();
    },
  },

  mounted() {
    this.$watch(
      "labelList",
      (newValue) => {
        if (!this.chart && newValue.length > 0) {
          this.initialChart();
        }
      },
      { immediate: true }
    );
  },
};
</script>
