import { publicApiHost } from "@/constants/urls.js";
import axios from "axios";
import { mapIncludedToData } from "@/helpers/jsonapi";
import { computed, ref } from "vue";

async function getTeamDonations(campaignId, teamId, params) {
  const url = `${publicApiHost}/api/v1/campaign/${campaignId}/donations`;
  const { data, headers } = await axios.get(url, {
    params: {
      ...params,
      team_id: teamId,
    },
  });
  const dataWithIncluded = mapIncludedToData(data.data, data.included);

  return {
    data: dataWithIncluded,
    count: headers["x-total-donations"],
    search: headers["x-search-donations"],
  };
}

export function usePublicTeamDonationList() {
  const donations = ref([]);
  const loading = ref(false);
  const donationsTotal = ref(0);

  const donationsTotals = computed(() => {
    let amount = 0;
    let total = Number(donationsTotal.value);

    let donationsCurrencyCode = "";
    if (donations.value.length > 0) {
      donationsCurrencyCode = donations.value[0].attributes.currency_code;
    }

    for (var donation of donations.value) {
      amount += donation.attributes.total;
    }

    return { donationsTotal: total, effectiveAmount: amount, donationsCurrencyCode };
  });

  async function fetchTeamDonations(campaignId, teamId, params) {
    loading.value = true;

    try {
      const { data, search } = await getTeamDonations(campaignId, teamId, params);
      donationsTotal.value = search;
      donations.value = data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    donations,
    donationsTotal,
    donationsTotals,
    loading,
    fetchTeamDonations,
  };
}
