var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"donor-component"},[_c('div',{staticClass:"donor-wrapper"},[_c('main-header'),_c('div',{staticClass:"container-fluid d-flex align-items-start justify-content-between main"},[(!_vm.success && !_vm.error && !_vm.forbidden)?_c('div',{staticClass:"tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"},[_c('div',{staticClass:"recurring-plan__text",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'donor_dashboard.cancel_recurring_plan_notice',
              `<p>You are about to cancel your recurring donation plan.</p>
              <p>Please note that this action cannot be undone.</p>`
            )
          )}}),_c('b-button',{staticClass:"bttn bttn--lg bttn--blue recurring-plan__btn",attrs:{"disabled":_vm.loading},on:{"click":_vm.cancelRecurringPlan}},[(_vm.loading)?_c('b-spinner',{attrs:{"variant":"white","small":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("donor_dashboard.cancel_recurring_plan_action", "Yes, cancel my plan")))])],1)],1):(_vm.success && !_vm.error)?_c('div',{staticClass:"tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"},[_c('div',{staticClass:"recurring-plan__text",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'donor_dashboard.cancel_recurring_plan_notice_success',
              `<p>Your recurring donation plan has been successfully cancelled.</p>`
            )
          )}})]):(!_vm.success && _vm.error)?_c('div',{staticClass:"tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"},[_c('div',{staticClass:"recurring-plan__text",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'donor_dashboard.cancel_recurring_plan_notice_error',
              `<p>There was an error cancelling your recurring donation plan. Please contact support.</p>`
            )
          )}})]):(!_vm.success && _vm.forbidden)?_c('div',{staticClass:"tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"},[_c('div',{staticClass:"recurring-plan__text",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'donor_dashboard.cancel_recurring_plan_notice_forbidden',
              `<p>Token not valid or expired.</p>`
            )
          )}})]):_vm._e()]),_c('CharidyV2Footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }