import urls from "../../constants/urls.js";
import axios from "axios";
import store from "../store";

import router from "@/router";
import { mapIncludedToData } from "@/helpers/jsonapi.js";

const safeJsonParse = (data, defaults) => {
  try {
    return JSON.parse(data);
  } catch {
    return defaults;
  }
};

const state = {
  account: [],
  orgId: null,
  accessTitle: "primary",
  loadingAccount: true,
  entities: [],
  entitiesIncluded: [],
  loadingEntities: true,
  contacts: [],
  loadingCotacts: true,
  timeZones: [],
  languages: [],
  countries: [],
  currencyList: [],
  subAccountList: [],
  organizations: [],
  needSelectOrg: false,
  blured: false,
  loadingAccess: true,
  settings: null,
  userCountry: "",
  access: {
    CanSeeTrainingVideos: false,
    CanSeeDesignTemplates: false,
    CanSubmitDesignTemplatesRequest: false,
    CanEditCampaignContent: false,
    CanDeleteAllCampaignTeams: false,
    CanSeeCampaignDetails: false,
    CanEditCampaignDetails: false,
    CanCreateCampaign: false,
    CanEditCampaignMedia: false,
    CanEditCampaignMatchers: false,
    CanSeeCampaignTeams: false,
    CanAddCampaignTeams: false,
    CanEditCampaignTeams: false,
    CanSeeCampaignRemindList: false,
    CanSeeCampaignList: false,
    CanSeeCampaignDashboard: false,
    CanSeeCampaignDonationList: false,
    CanExportCampaignDonationList: false,
    CanEditCampaignDonation: false,
    CreateOfflineCampaignDonation: false,
    CanImportOfflineCampaignDonation: false,
    CanSeeOrganizationContactList: false,
    CanEditOrganizationContactList: false,
    CanSeeOrganizationEntityList: false,
    CanEditOrganizationEntityList: false,
    CanSeeAndEditOrganizationGatewayList: false,
    CanSeeAndEditOrganizationIntegrationList: false,
    CanEditOrganizationDetail: false,
    CanSeeOrganizationDetail: false,
    CanSeeAllOrgDonationList: false,
    CanExportAllOrgDonationList: false,
    CanManageOtherAccountAccess: false,
    CanSeeOrganizationGatewayList: false,
    CanEditOrganizationGatewayList: false,
    CanPerformDonationSearch: false,
    CanExportOrganizationReceiptList: false,
    CanEditCampaignDonationLevels: false,
  },
  designTemplatesEnabled: false,
  submitDesignTemplatesRequestEnabled: false,
  createYearRoundCampaign: false,
  addCustomLinkToTeam: false,
  canSeeReports: !false,
  showFundTransferSection: false,
  canSeeTrainingVideos: false,
  canSeeEmailTemplates: false,
  showCreateOfflineDonationWithReceiptBtn: false,
  showCreateOfflineDonationWithReceiptCID: 0,
  canSeeTickets: false,
  teamSMSMessaging: false,
  addressAutocompleteLegalEntity: false,
  campaignWizardVersion: {},
  allowMatcherCoefChange: {},
  twoFAActive: false,
  acl: {},
  orgSettingsLoaded: false,
  dashboardGatewaysTabList: {},
  addSetCampaignModeSetting: false,
  receiptEmailGatewayList: {},
  allowAllDonorExport: false,
  enableOrganizationPreuploadedDonors: false,
  enableChargeTeamLinkedCc: false,
  enableCharidyAssistant: false,
  accountLegalEntitiesComplianceQuestions: {},
};

const mutations = {
  setBlured(state, value) {
    state.blured = value;
  },

  setOrgSettingsLoaded(state, value) {
    state.orgSettingsLoaded = value;
  },

  setAccount(state, data) {
    state.account = data;
  },

  setOrgId(state, data) {
    state.orgId = data;
  },

  setLoadingAccount(state, value) {
    state.loadingAccount = value;
  },

  setEntities(state, value) {
    state.entities = value;
  },

  setEntitiesIncluded(state, value) {
    state.entitiesIncluded = value;
  },

  setLoadingEntities(state, value) {
    state.loadingEntities = value;
  },

  setTimeZones(state, value) {
    state.timeZones = value;
  },

  setLanguages(state, value) {
    state.languages = value;
  },

  setContacts(state, value) {
    state.contacts = value;
  },

  setLoadingContacts(state, value) {
    state.loadingCotacts = value;
  },

  setCountries(state, value) {
    state.countries = value;
  },

  setCurrencyList(state, value) {
    state.currencyList = value;
  },

  setOrganizations(state, value) {
    state.organizations = value;
  },

  setNeedSelectOrg(state, value) {
    state.needSelectOrg = value;
  },

  setSubAccountList(state, value) {
    state.subAccountList = value;
  },
  setAccess(state, value) {
    for (const key in state.access) {
      state.access[key] = value;
    }
  },
  setAccessTitle(state, value) {
    state.accessTitle = value;
  },
  setAccessByList(state, value) {
    for (const key of value) {
      state.access[key] = true;
    }
  },
  setLoadingAccess(state, value) {
    state.loadingAccess = value;
  },

  setDesignTemplatesEnabled(state, value) {
    state.designTemplatesEnabled = value;
  },

  setSubmitDesignTemplatesRequestEnabled(state, value) {
    state.submitDesignTemplatesRequestEnabled = value;
  },

  setCreateYearRoundCampaign(state, value) {
    state.createYearRoundCampaign = value;
  },

  setAddCustomLinkToTeam(state, value) {
    state.addCustomLinkToTeam = value;
  },

  setAccountSettings(state, value) {
    state.settings = value;
  },
  setCanSeeReports(state, value) {
    state.canSeeReports = value;
  },
  canSeeTickets(state, value) {
    state.canSeeTickets = value;
  },
  setCanSeeVideoTutorials(state, value) {
    state.canSeeVideoTutorials = value;
  },
  setShowFundTransferSection(state, value) {
    state.showFundTransferSection = value;
  },
  setShowTrainingVideos(state, value) {
    state.canSeeTrainingVideos = value;
  },
  setShowEmailTemplates(state, value) {
    state.canSeeEmailTemplates = value;
  },
  setShowCreateOfflineDonationWithReceiptBtn(state, { value, value_int }) {
    state.showCreateOfflineDonationWithReceiptBtn = value;
    state.showCreateOfflineDonationWithReceiptCID = value_int;
  },
  setOrgSetting(s, { stateName, value }) {
    s[stateName] = value;
  },
  setUserCountry(s, value) {
    s.userCountry = value;
  },
  setAccountTwoFAActive(s, value) {
    s.twoFAActive = value;
  },
  setACL(s, data) {
    s.acl = (data || []).reduce((res, el) => {
      if (el.type !== "restricted_acl_item") {
        return res;
      }

      const prev = res[el.attributes.action];
      res[el.attributes.action] = {
        campaign_ids: [...(prev?.campaign_ids || []), el.attributes.campaign_id],
      };
      return res;
    }, {});
  },
};

const getters = {
  getEntitiesIncludeByIdAndType(state) {
    return (id, type = false) => {
      const filteredArr = state.entitiesIncluded.filter(function (i) {
        if (type) {
          return i.id === id && i.type === type;
        }
        return i.id === id;
      });

      return filteredArr.length > 0 ? filteredArr[0] : null;
    };
  },
  getOrgTimezone: (_, getters) => {
    return getters.getCurrentOrganization?.attributes?.timezone;
  },
  getOldLangsVariant(state) {
    return state.languages.map((v) => ({
      short: v.attributes.code,
      long: v.attributes.name,
    }));
  },

  hasAcceess(s) {
    return (rule, cid = 0) => {
      const ruleData = s.acl[rule];

      if (ruleData) {
        if (ruleData.campaign_ids.length === 1 && ruleData.campaign_ids[0] === 0) {
          return true;
        } else {
          return ruleData.campaign_ids.includes(Number(cid));
        }
      }

      return s.access[rule];
    };
  },

  getCurrentOrganization(s) {
    const orgId = localStorage.getItem("orgId");
    return s.organizations.find((el) => el.id === orgId) || null;
  },
};

const actions = {
  getAccountInfo({ commit }) {
    commit("setLoadingAccount", true);

    axios
      .get(urls.getOrganizations)
      .then((response) => {
        if (response) {
          let accountData = response.data.data;
          store.dispatch("getCurrencies");
          let primaryAcc = accountData.filter((el) => {
            return el.attributes.primary;
          });

          let secondaryAcc = accountData.filter((el) => {
            return !el.attributes.primary;
          });

          let orgId = localStorage.getItem("orgId");
          let selectedOrg = [];

          if (orgId) {
            selectedOrg = accountData.filter((el) => {
              return el.id == orgId;
            });
          }

          if (selectedOrg.length > 0) {
            store.dispatch("getAccountOrg");
          } else if (primaryAcc.length == 1) {
            commit("setOrgId", primaryAcc[0].id);
            localStorage.setItem("orgId", primaryAcc[0].id);
            store.dispatch("getAccountOrg");
          } else if (secondaryAcc.length == 1) {
            commit("setOrgId", secondaryAcc[0].id);
            localStorage.setItem("orgId", secondaryAcc[0].id);
            store.dispatch("getAccountOrg");
          }

          if (response.data.data.length > 1) {
            commit("setOrganizations", response.data.data);
          }

          if (secondaryAcc.length > 1 && primaryAcc.length == 0 && selectedOrg.length == 0) {
            commit("setOrgId", null, { root: true });
            localStorage.removeItem("orgId");
          }

          if (response.data.data.length == 0) {
            commit("setOrgId", null, { root: true });
            localStorage.removeItem("orgId");
          }

          if (!localStorage.getItem("orgId")) {
            commit("setNeedSelectOrg", true);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getCurrencies({ commit }) {
    axios.get(urls.getCurrency).then((response) => {
      commit("setCurrencyList", response.data.data);
    });
  },

  updateOrganizationsList({ commit }) {
    axios
      .get(urls.getOrganizations)
      .then((response) => {
        commit("setOrganizations", response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getAccountOrg({ state, commit }) {
    let orgId = localStorage.getItem("orgId");

    commit("setLoadingAccount", true);

    axios
      .get(urls.getAccountOrgById.replace(":orgId", orgId))
      .then((response) => {
        commit("setAccount", response.data);
        commit("setLoadingAccess", true);
        if (!state.account.data.attributes.primary) {
          commit("setAccess", false);
          store.dispatch("getAccountAccess");
        } else {
          commit("setAccess", true);
          commit("setLoadingAccess", false);
        }
        commit("setLoadingAccount", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getAccountAccess({ commit }) {
    const accountId = store.state.user.user.id;
    const orgId = localStorage.getItem("orgId");
    axios
      .get(urls.getAccountAccess.replace(":orgId", orgId).replace(":accountId", accountId))
      .then((response) => {
        const { attributes } = response.data.data;

        commit("setAccessTitle", attributes.access);
        commit("setAccessByList", attributes.acl_list);
        commit("setACL", response.data.included);
        commit("setLoadingAccess", false);
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
      });
  },

  updateAccountOrg({ commit }) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(urls.getAccountOrgById.replace(":orgId", orgId))
      .then((response) => {
        commit("setAccount", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  updateAccountInfo({ commit }) {
    axios
      .get(urls.account)
      .then((response) => {
        let accountData = response.data;
        commit("setOrgId", response.data.data.id);
        localStorage.setItem("orgId", response.data.data.id);
        commit("setAccount", accountData);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getEntities({ commit }) {
    commit("setLoadingEntities", true);

    let orgId = localStorage.getItem("orgId");
    axios
      .get(urls.entities.replace(":orgId", orgId))
      .then((response) => {
        let entitiesData = response.data.data;
        let entitiesIncluded = response.data.included || [];
        commit("setEntities", entitiesData);
        commit("setEntitiesIncluded", entitiesIncluded);
        commit("setLoadingEntities", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getTimeZones({ commit }) {
    return axios.get(urls.gettimeZone).then((response) => {
      let timezone = response.data.data;
      commit("setTimeZones", timezone);

      return timezone;
    });
  },

  getLanguages({ commit, getters }) {
    return axios.get(urls.getLanguages).then(({ data }) => {
      const { data: languages } = data;
      commit("setLanguages", languages);
      return getters.getOldLangsVariant;
    });
  },

  getContacts({ commit }) {
    commit("setLoadingContacts", true);
    let orgId = localStorage.getItem("orgId");
    axios
      .get(urls.postGetContacts.replace(":orgId", orgId))
      .then((response) => {
        let contacts = response.data.data;
        commit("setContacts", contacts);
        commit("setLoadingContacts", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getCountries({ commit }) {
    axios
      .get(urls.getCountries)
      .then((response) => {
        let countries = response.data.data;
        commit("setCountries", countries);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getSubaccountList({ commit }) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(urls.subAccount.replace(":orgId", orgId))
      .then((response) => {
        let subAccountList = mapIncludedToData(response.data.data, response.data.included);
        commit("setSubAccountList", subAccountList);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  deleteSubAccount({ dispatch }, { id }) {
    let orgId = localStorage.getItem("orgId");

    return axios
      .delete(urls.patchSubAccount.replace(":orgId", orgId).replace(":sucAccId", id))
      .then(() => {
        dispatch("getSubaccountList");
      });
  },

  changeOrganization({ commit }, id) {
    commit("setOrgId", id);
    localStorage.setItem("orgId", id);

    router.push("/dashboard");

    store.dispatch("getAccountOrg");
  },

  changeOrganizationSettings({ commit }, id) {
    commit("setOrgId", id);
    localStorage.setItem("orgId", id);

    router.push("/account");

    store.dispatch("getAccountOrg");
  },

  updateUserAccessSettings({ commit }) {
    return axios.get(urls.signin).then((res) => {
      const { settings, twofa_active } = res.data.data.attributes;

      commit("setAccountTwoFAActive", twofa_active);
      commit("setAccountSettings", settings);

      return settings || {};
    });
  },

  updateOrgSettingsAccess({ commit }) {
    commit("setOrgSettingsLoaded", false);
    let orgId = localStorage.getItem("orgId");
    return axios
      .get(urls.organizationSettings.replace(":orgId", orgId))
      .then((res) => {
        commit("setOrgSettingsLoaded", true);
        res.data.forEach(function (item) {
          switch (item.setting) {
            case "view_design_template":
              commit("setDesignTemplatesEnabled", item.value);
              break;
            case "submit_design_template_request":
              commit("setSubmitDesignTemplatesRequestEnabled", item.value);
              break;
            case "create_year_round_campaign":
              commit("setCreateYearRoundCampaign", item.value);
              break;
            case "add_custom_link_to_team":
              commit("setAddCustomLinkToTeam", item.value);
              break;
            case "show_fund_transfer_section":
              commit("setShowFundTransferSection", item.value);
              break;
            case "show_reports":
              commit("setCanSeeReports", item.value);
              break;
            case "show_tickets":
              commit("canSeeTickets", item.value);
              break;
            case "show_training_videos":
              commit("setShowTrainingVideos", item.value);
              break;
            case "show_email_templates":
              commit("setShowEmailTemplates", item.value);
              break;
            case "show_create_offline_donation_with_receipt_btn":
              commit("setShowCreateOfflineDonationWithReceiptBtn", item);
              break;
            case "team_sms_messaging":
              commit("setOrgSetting", {
                stateName: "teamSMSMessaging",
                value: item.value,
              });
              break;
            case "org_notifications_from_email":
              commit("setOrgSetting", {
                stateName: "orgNotificationsFromEmail",
                value: item.value,
              });
              break;
            case "use_stripe_onboarding":
              commit("setOrgSetting", {
                stateName: "useStripeOnboarding",
                value: item.value,
              });
              break;
            case "allow_all_donor_export":
              commit("setOrgSetting", {
                stateName: "allowAllDonorExport",
                value: item.value,
              });
              break;
            case "address_autocomplete_legal_entity":
              commit("setOrgSetting", {
                stateName: "addressAutocompleteLegalEntity",
                value: item.value,
              });
              break;
            case "campaign_wizard_version":
              commit("setOrgSetting", {
                stateName: "campaignWizardVersion",
                value: {
                  value: item.value,
                  value_int: item.value_int,
                  value_extend: safeJsonParse(item.value_extend, []),
                },
              });
              break;
            case "allow_matcher_coef_change":
              commit("setOrgSetting", {
                stateName: "allowMatcherCoefChange",
                value: {
                  value: item.value,
                },
              });
              break;
            case "dashboard_gateways_tab_list":
              commit("setOrgSetting", {
                stateName: "dashboardGatewaysTabList",
                value: {
                  value: item.value,
                  value_int: item.value_int,
                  value_extend: safeJsonParse(item.value_extend, {}),
                },
              });
              break;
            case "add_set_campaign_mode_setting":
              commit("setOrgSetting", {
                stateName: "addSetCampaignModeSetting",
                value: item.value,
              });
              break;
            case "receipt_email_gateway_list":
              commit("setOrgSetting", {
                stateName: "receiptEmailGatewayList",
                value: {
                  value: item.value,
                  value_int: item.value_int,
                  value_extend: safeJsonParse(item.value_extend, []),
                },
              });
              break;
            case "enable_organization_preuploaded_donors": {
              commit("setOrgSetting", {
                stateName: "enableOrganizationPreuploadedDonors",
                value: item.value,
              });
              break;
            }
            case "enable_charge_team_linked_cc": {
              commit("setOrgSetting", {
                stateName: "enableChargeTeamLinkedCc",
                value: item.value,
              });
              break;
            }
            case "enable_charidy_assistant": {
              commit("setOrgSetting", {
                stateName: "enableCharidyAssistant",
                value: item.value,
              });
              break;
            }
            case "account_legal_entities_compliance_questions": {
              commit("setOrgSetting", {
                stateName: "accountLegalEntitiesComplianceQuestions",
                value: safeJsonParse(item.value_extend, {}),
              });
              break;
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
        commit("setOrgSettingsLoaded", false);
      });
  },

  async initData({ commit }, { page } = {}) {
    const response = await axios.get(urls.initPage, { params: { page } });
    commit("setUserCountry", response.headers["x-geo-ip-country"]);
    return response;
  },

  saveExtraEmails(_, { payload } = {}) {
    let oid = localStorage.getItem("orgId");

    return axios.post(urls.orgExtraEmails.replace(":oid", oid), payload);
  },

  saveNotificationsFromEmail(_, { payload } = {}) {
    let oid = localStorage.getItem("orgId");

    return axios.post(urls.orgNotificationsFromEmail.replace(":oid", oid), payload);
  },

  saveOrgNameLanguages(_, { payload } = {}) {
    let oid = localStorage.getItem("orgId");

    return axios.post(urls.orgNameLanguages.replace(":oid", oid), payload);
  },

  async saveMessageTemplates(_, { type, payload } = {}) {
    let oid = localStorage.getItem("orgId");

    const { data } = await axios.post(
      urls.orgMessageTemplates.replace(":oid", oid).replace(":type", type),
      payload
    );

    return data;
  },

  async deleteMessageTemplates(_, { type, id } = {}) {
    let oid = localStorage.getItem("orgId");

    await axios.delete(
      urls.orgMessageTemplatesId.replace(":oid", oid).replace(":type", type).replace(":id", id)
    );
  },

  async getMessageTemplates(_, { type } = {}) {
    let oid = localStorage.getItem("orgId");

    const { data } = await axios.get(
      urls.orgMessageTemplates.replace(":oid", oid).replace(":type", type)
    );

    return data;
  },

  async getOrgSMSCount() {
    const orgId = localStorage.getItem("orgId");
    const { data } = await axios.get(urls.orgSMSCount(orgId));
    return data?.count_sms || 0;
  },

  async getOrgSMSLimit() {
    const orgId = localStorage.getItem("orgId");
    const { data } = await axios.get(urls.orgSMSLimit(orgId));
    return data?.smsLimitOrgSetting || 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
