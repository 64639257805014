<template>
  <multiselect-search
    v-model="optionSelected"
    :open-prefetch="true"
    :allow-empty="allowEmpty"
    :search-url="getSearchUrl"
    :search-options="searchOptions"
    :taggable="taggable"
    :multiple="multiple"
    :multiselectClass="{ 'multiselect-charidy': !defaultStyles }"
    skip-external-serching
    :closeOnSelect="true"
    :name="name"
    class="w-100"
  >
  </multiselect-search>
</template>

<script>
import urls from "@/constants/urls";
import { mapGetters, mapState } from "vuex";
import MultiselectSearch from "@/components/multiselect-search.vue";
import { getFavoriteCampaign, setFavoriteCampaign } from "@/helpers/storage";

const extendParams = [
  "campaign_stats",
  "media",
  "meta",
  "donation_levels",
  "donation_streams",
  "url_alias",
];

export default {
  components: {
    MultiselectSearch,
  },
  props: {
    value: {
      type: [Number, String, Object, Array],
      default: 0,
    },
    getFullCampaign: Boolean,
    defaultStyles: Boolean,
    preselectFirst: Boolean,
    allowEmpty: Boolean,
    taggable: Boolean,
    multiple: Boolean,
    perPage: {
      type: Number,
      default: 100,
    },
    general: Boolean,
    name: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      innerSelected: null,
    };
  },

  mounted() {},

  methods: {
    selectFavorite(options) {
      const selectCampaignFavorite = getFavoriteCampaign() || null;
      const optionOrNull = options.find((c) => c.id === selectCampaignFavorite) || options[0];

      if (optionOrNull && !this.allowEmpty) {
        if (this.multiple) {
          this.optionSelected = [optionOrNull];
        } else {
          this.optionSelected = optionOrNull;
        }

        return true;
      }

      return false;
    },

    selectFromQuery() {
      const cidQuery = this.$route.query.cid;
      if (!cidQuery) {
        return false;
      }

      const cid = Number(cidQuery);
      if (isNaN(cid)) {
        return false;
      }

      return cid;
    },

    selectOption() {},

    async getCampaignData(cid) {
      let props = `?${[
        "extend=media",
        "extend=meta",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`;

      const campaign = await this.$store.dispatch("getCampaign", {
        cid,
        props,
      });

      if (campaign?.data) {
        this.innerSelected = {
          id: campaign.data.id,
          ...campaign.data.attributes,
        };
        this.optionSelected = this.innerSelected;
      }
    },
  },

  computed: {
    ...mapState({
      mainCampaigns: (s) => s.campaigns.campaigns,
      toolsCampaigns: (s) => s.tools.campaigns,
    }),
    ...mapGetters({
      getCampaignOptionsDashboard: "getCampaignOptionsDashboard",
    }),

    campaigns() {
      if (this.mainCampaigns.length > 0) {
        return this.mainCampaigns;
      }

      if (this.toolsCampaigns.length > 0) {
        return this.toolsCampaigns;
      }

      return [];
    },

    optionSelected: {
      set(val) {
        this.innerSelected = val;
        setFavoriteCampaign(val.id);
        if (this.getFullCampaign) {
          const cmp = this.campaigns.find((e) => e.id === val.id);
          this.$emit("input", cmp);
        } else {
          if (this.multiple) {
            this.$emit("input", val);
          } else {
            this.$emit("input", val.id);
          }
        }
      },
      get() {
        return this.innerSelected;
      },
    },

    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");

      return urls.getCampaignList.replace(":orgId", orgID);
    },

    searchOptions() {
      return {
        placeholder: "Select campaign",
        changeParams: ({ page }) => ({
          page: page,
          perPage: this.perPage,
          sort_by: ["-startdate", "campaign_mode_dashboard"],
          extend: extendParams,
        }),
        customLabel: (e) => `[${e.id}] ${e.title}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
            __origin: el,
          })),
        onListUpdate: (data) => {
          if (data?.length >= this.campaigns?.length) {
            const list = data.map((el) => el.__origin);
            this.$store.commit("setCampaigns", list);
          }
        },
        onIncludedUpdate: (data) => {
          this.$store.commit("setIncludedMedia", data);
        },
      };
    },
  },

  watch: {
    getCampaignOptionsDashboard: {
      handler(list) {
        const fromQuery = this.selectFromQuery();
        if (fromQuery) {
          this.getCampaignData(fromQuery);
          return;
        }

        if (this.general) {
          return;
        }

        if (list.length > 0) {
          if (this.preselectFirst) {
            if (this.multiple) {
              this.optionSelected = [list[0]];
            } else {
              this.optionSelected = list[0];
            }

            return;
          }

          this.selectFavorite(list);
        }
      },
      immediate: true,
    },

    value: {
      handler(cid) {
        if (cid && typeof cid === "number" && this.innerSelected?.id !== cid) {
          this.getCampaignData(cid);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect-charidy {
  width: 100%;
  min-height: 60px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  .multiselect__select {
    height: 58px;
    &:before {
      top: 60%;
    }
  }
  .multiselect__option span {
    display: block;
    word-wrap: break-word;
    width: 100%;
    white-space: normal;
  }
  .multiselect__tags {
    min-height: 60px;
  }
  .multiselect__single,
  .multiselect__placeholder {
    font-size: 20px;
    min-height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (min-width: 768px) {
      font-size: 26px;
    }
    @media only screen and (min-width: 992px) {
      font-size: 30px;
    }
  }
  .multiselect__input {
    min-height: 40px;
    line-height: 40px;
  }
}
</style>
