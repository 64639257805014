<template>
  <router-link to="/donor-dashboard" custom v-slot="{ navigate, isActive, isExactActive }">
    <button
      class="tw-w-full tw-h-[56px] tw-flex tw-text-start tw-p-[15px] tw-ps-[60px] custom-xl:tw-ps-[40px] tw-rounded-r-[50px] tw-bg-[#50cdaa] tw-border-[#3CC7A0] tw-text-[#313131] tw-outline-none tw-text-[16px] tw-shadow-lg tw-border hover:tw-font-vito-bold hover:tw-no-underline"
      :class="{
        'tw-font-vito-bold tw-no-underline': isExactActive,
        'tw-font-vito-bold tw-no-underline': isActive,
      }"
      @click="onNavItemClick($event, navigate)"
    >
      {{ $t("dashboard.donor_dashboard_page", "Donor dashboard") }}
    </button>
  </router-link>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    onNavItemClick(e, navigate) {
      navigate(e);
      this.$emit("click");
    },
  },
});
</script>
