<template>
  <div>
    <canvas id="team-donation-chart"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment";
export default {
  props: ["donation"],
  data() {
    return {
      teamDonationChart: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Number of Donors",
              data: [],
              backgroundColor: "#70acc100",
              borderColor: "#107598",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Chart.js Line Chart",
            },
          },
        },
      },
    };
  },
  watch: {
    donation() {
      this.getTeamDonationChartData();
    },
  },
  methods: {
    initChart(chartObj) {
      const ctx = document.getElementById("team-donation-chart");
      new Chart(ctx, chartObj);
    },
    getTeamDonationChartData() {
      for (let i = 0; i < this.donation.length; i++) {
        let beforeIndex = this.teamDonationChart.data.labels.length - 1;
        let labelsArr = this.teamDonationChart.data.labels;
        let beforeIndexDataVal = this.teamDonationChart.data.datasets[0].data[beforeIndex];

        //Check if the donation date is the same as the previous donation date Add the donation to the previous date
        if (this.getDateFormat(this.donation[i].attributes.date) === labelsArr[beforeIndex]) {
          this.teamDonationChart.data.datasets[0].data[beforeIndex] = beforeIndexDataVal + 1;
        } else {
          this.teamDonationChart.data.labels.push(
            this.getDateFormat(this.donation[i].attributes.date)
          );
          this.teamDonationChart.data.datasets[0].data.push(1);
        }
      }
      this.initChart(this.teamDonationChart);
    },
    getDateFormat: function (date) {
      return moment.unix(date).format("MMMM D, YYYY");
    },
  },
};
</script>

<style lang="scss" scoped></style>
