import Vuex from "vuex";
import Vue from "vue";
import vuexI18n from "vuex-i18n";
import VuejsDialog from "vuejs-dialog";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import Cookies from "js-cookie";
import VuexPersistence from "vuex-persist";
import Vue2Filters from "vue2-filters";

import translation from "./modules/translation.js";
import campaigns from "./modules/campaigns.js";
import campaign from "./modules/campaign.js";
import gateways from "./modules/gateways.js";
import payouts from "./modules/payouts.js";
import account from "./modules/account.js";
import video from "./modules/video.js";
import teams from "./modules/teams.js";
import reminder from "./modules/reminder.js";
import donation from "./modules/donation.js";
import dashboard from "./modules/dashboard/index.js";
import user from "./modules/user.js";
import integrations from "./modules/integrations.js";
import donorStatistic from "./modules/donorStatistic.js";
import donorAccount from "./modules/donorAccount.js";
import donorTeams from "./modules/donorTeams.js";
import stripe from "./modules/stripe.js";
import donorDonations from "./modules/donorDonations.js";
import reports from "./modules/reports";
import leads from "./modules/leads";
import journalAd from "./modules/journalAd";
import tools from "./modules/tools";
import ticket from "./modules/ticket";
import content from "./modules/content";
import expressCampaign from "./modules/expressCampaign";
import campaignShare from "./modules/campaignShare.js";
import campaignTeamDonation from "./modules/campaignTeamDonation.js";
import messaging from "./modules/messaging.js";
import subAccounts from "./modules/subAccounts.js";
import mediaStorage from "./modules/mediaStorage.js";
import fundTransferStatus from "./modules/fundTransferStatus.js";
import recurringDonationsSummary from "./modules/recurringDonationsSummary.js";
import seferTorahPages from "./modules/seferTorahPages.js";
import shareVideo from "./modules/shareVideo.js";
import orgTickets from "./modules/orgTickets.js";
import calendar from "./modules/calendar.js";
import storage from "./modules/storage.js";
import featureHide from "./modules/featureHide.js";
import foundationRequest from "./modules/foundationRequest.js";

import CustomView from "@/components/confirm-with-checkbox.vue";
const confirmDeleteWidthCheckbox = "delete-width-checkbox-confirm";

import vendorMain from "./modules/vendorMain";

import donorOrgRoles from "./modules/donorOrgRoles";

Vue.use(Vuex);
Vue.use(Vue2Filters);
Vue.use(VuejsDialog);
Vue.dialog.registerComponent(confirmDeleteWidthCheckbox, CustomView);

const vuexLocal = new VuexPersistence({
  // storage: window.localStorage,
  modules: ["user"],
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 3,
    }),
});

const config = {
  translateFilterName: "t",
};

const store = new Vuex.Store({
  modules: {
    translation,
    campaigns,
    gateways,
    payouts,
    account,
    campaign,
    video,
    teams,
    reminder,
    donation,
    dashboard,
    user,
    integrations,
    donorStatistic,
    donorAccount,
    stripe,
    donorDonations,
    vendorMain,
    reports,
    leads,
    journalAd,
    tools,
    ticket,
    content,
    expressCampaign,
    campaignShare,
    campaignTeamDonation,
    messaging,
    donorTeams,
    subAccounts,
    mediaStorage,
    fundTransferStatus,
    rds: recurringDonationsSummary,
    seferTorahPages,
    shareVideo,
    organizationRoles: donorOrgRoles,
    orgTickets,
    calendar,
    storage,
    featureHide,
    foundationRequest,
  },
  plugins: [vuexLocal.plugin],
});

Vue.use(vuexI18n.plugin, store, config);

Vue.i18n.set(translation.state.locate || "en");
Vue.i18n.fallback("en");

store.watch(store.getters.selectedLang, () => {
  const translation = store.state.translation;
  if (translation.locate != null) {
    Vue.i18n.set(translation.locate || "en");
    store.dispatch("getTranslation");
  }
});

store.watch(store.getters.changeTranslation, () => {
  const translation = store.state.translation;
  if (translation.locate != "en") {
    Vue.i18n.fallback("en");
    Vue.i18n.add(translation.locate, translation.translation[translation.locate]);
    Vue.i18n.add("en", translation.translation["en"]);
  } else {
    Vue.i18n.fallback("en");
    Vue.i18n.add("en", translation.translation["en"]);
  }
});

export const useStore = () => store;

export default store;
