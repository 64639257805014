<script>
import MainHeader from "./common-components/main-header.vue";
import CharidyV2Footer from "@/common-components/cdn-v2/cdn-footer.vue";
import { getCookie } from "@/helpers/cookie";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  components: {
    MainHeader,
    CharidyV2Footer,
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      forbidden: false,
    };
  },
  created() {
    let lang = getCookie("language");
    this.$store.dispatch("setTranslationPermanent", lang);
    this.$store.dispatch("getTranslation");
  },
  watch: {
    jwtAuthCode: {
      handler(code) {
        if (!code) {
          this.$router.push({ path: "/donor-dashboard" });
        }
      },
      immediate: true,
    },
  },
  computed: {
    jwtAuthCode() {
      return this.$route.params?.jwtAuthCode;
    },
  },
  methods: {
    cancelRecurringPlan() {
      this.loading = true;
      this.$store
        .dispatch("cancelRecurringPlan", this.jwtAuthCode)
        .then(({ data }) => {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            this.$router.push({ path: "/donor-dashboard" });
          }, 5000);
          this.success = data?.success;
        })
        .catch((e) => {
          const { status } = e.response || {};
          if (status === 400) {
            this.forbidden = true;
            return;
          }
          this.error = true;
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div class="donor-component">
    <div class="donor-wrapper">
      <main-header></main-header>
      <div class="container-fluid d-flex align-items-start justify-content-between main">
        <div
          class="tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"
          v-if="!success && !error && !forbidden"
        >
          <div
            class="recurring-plan__text"
            v-html="
              $t(
                'donor_dashboard.cancel_recurring_plan_notice',
                `<p>You are about to cancel your recurring donation plan.</p>
                <p>Please note that this action cannot be undone.</p>`
              )
            "
          />
          <b-button
            @click="cancelRecurringPlan"
            :disabled="loading"
            class="bttn bttn--lg bttn--blue recurring-plan__btn"
          >
            <b-spinner v-if="loading" variant="white" small></b-spinner>
            <span>{{
              $t("donor_dashboard.cancel_recurring_plan_action", "Yes, cancel my plan")
            }}</span>
          </b-button>
        </div>

        <div
          class="tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"
          v-else-if="success && !error"
        >
          <div
            class="recurring-plan__text"
            v-html="
              $t(
                'donor_dashboard.cancel_recurring_plan_notice_success',
                `<p>Your recurring donation plan has been successfully cancelled.</p>`
              )
            "
          />
        </div>

        <div
          class="tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"
          v-else-if="!success && error"
        >
          <div
            class="recurring-plan__text"
            v-html="
              $t(
                'donor_dashboard.cancel_recurring_plan_notice_error',
                `<p>There was an error cancelling your recurring donation plan. Please contact support.</p>`
              )
            "
          />
        </div>

        <div
          class="tw-py-[55px] tw-px-[3%] tw-text-[#a0a1ac] tw-text-center panel recurring-plan__panel"
          v-else-if="!success && forbidden"
        >
          <div
            class="recurring-plan__text"
            v-html="
              $t(
                'donor_dashboard.cancel_recurring_plan_notice_forbidden',
                `<p>Token not valid or expired.</p>`
              )
            "
          />
        </div>
      </div>
      <CharidyV2Footer />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.recurring-plan {
  &__panel {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    gap: 1rem;
    width: 16rem;
  }
  &__text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    letter-spacing: normal;
    color: #212529;
  }
}
</style>
